const SignatureText = (props: any) => {
  if (
    props.fieldsInfo.systemFieldName === "PatientSignature" ||
    props.fieldsInfo.systemFieldName === "PhysicianSignature"
  ) {
    return (
      <>
        <img src={props?.fieldsInfo?.fieldValue} alt="signature" />
      </>
    );
  } else return null;
};

export default SignatureText;
