import { toast } from "react-toastify";
const DymoLabel = async (content: any) => {
  const Dymo = require("dymojs"),
    dymo = new Dymo();
  // var labelXml = `<?xml version="1.0" encoding="utf-8"?>
  // <DieCutLabel Version="8.0" Units="twips">
  //   <PaperOrientation>Landscape</PaperOrientation>
  //   <Id>LargeAddress</Id>
  //   <IsOutlined>false</IsOutlined>
  //   <PaperName>30321 Large Address</PaperName>
  //   <DrawCommands>
  //     <RoundRectangle X="0" Y="0" Width="2025" Height="5020" Rx="270" Ry="270" />
  //   </DrawCommands>
  //   <ObjectInfo>
  //     <BarcodeObject>
  //       <Name>BARCODE</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
  //       <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
  //       <LinkedObjectName />
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>True</IsVariable>
  //       <GroupID>-1</GroupID>
  //       <IsOutlined>False</IsOutlined>
  //       <Text>${labelData.accessionNumber}</Text>
  //       <Type>Code39</Type>
  //       <Size>Small</Size>
  //       <TextPosition>Bottom</TextPosition>
  //       <TextFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />
  //       <CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />
  //       <TextEmbedding>None</TextEmbedding>
  //       <ECLevel>0</ECLevel>
  //       <HorizontalAlignment>Center</HorizontalAlignment>
  //       <QuietZonesPadding Left="0" Top="0" Right="0" Bottom="0" />
  //     </BarcodeObject>
  //     <Bounds X="907" Y="1410" Width="2880" Height="525" />
  //   </ObjectInfo>
  //   <ObjectInfo>
  //     <TextObject>
  //       <Name>TEXT</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
  //       <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
  //       <LinkedObjectName />
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>False</IsVariable>
  //       <GroupID>-1</GroupID>
  //       <IsOutlined>False</IsOutlined>
  //       <HorizontalAlignment>Left</HorizontalAlignment>
  //       <VerticalAlignment>Top</VerticalAlignment>
  //       <TextFitMode>ShrinkToFit</TextFitMode>
  //       <UseFullFontHeight>True</UseFullFontHeight>
  //       <Verticalized>False</Verticalized>
  //       <StyledText>
  //         <Element>
  //           <String xml:space="preserve">${labelData.firstName}${labelData.lastName}</String>
  //           <Attributes>
  //             <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />
  //             <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
  //           </Attributes>
  //         </Element>
  //       </StyledText>
  //     </TextObject>
  //     <Bounds X="322" Y="225" Width="2250" Height="285" />
  //   </ObjectInfo>
  //   <ObjectInfo>
  //     <DateTimeObject>
  //       <Name>DATE-TIME</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
  //       <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
  //       <LinkedObjectName />
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>False</IsVariable>
  //       <GroupID>-1</GroupID>
  //       <IsOutlined>False</IsOutlined>
  //       <HorizontalAlignment>Left</HorizontalAlignment>
  //       <VerticalAlignment>Top</VerticalAlignment>
  //       <TextFitMode>ShrinkToFit</TextFitMode>
  //       <UseFullFontHeight>True</UseFullFontHeight>
  //       <Verticalized>False</Verticalized>
  //       <DateTimeFormat>LongSystemDate</DateTimeFormat>
  //       <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />
  //       <PreText />
  //       <PostText />
  //       <IncludeTime>False</IncludeTime>
  //       <Use24HourFormat>False</Use24HourFormat>
  //     </DateTimeObject>
  //     <Bounds X="2992" Y="315" Width="1665" Height="225" />
  //   </ObjectInfo>
  //   <ObjectInfo>
  //     <TextObject>
  //       <Name>TEXT_1</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
  //       <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
  //       <LinkedObjectName />
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>False</IsVariable>
  //       <GroupID>-1</GroupID>
  //       <IsOutlined>False</IsOutlined>
  //       <HorizontalAlignment>Left</HorizontalAlignment>
  //       <VerticalAlignment>Top</VerticalAlignment>
  //       <TextFitMode>ShrinkToFit</TextFitMode>
  //       <UseFullFontHeight>True</UseFullFontHeight>
  //       <Verticalized>False</Verticalized>
  //       <StyledText>
  //         <Element>
  //           <String xml:space="preserve">${labelData.dateOfBirth}</String>
  //           <Attributes>
  //             <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />
  //             <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
  //           </Attributes>
  //         </Element>
  //       </StyledText>
  //     </TextObject>
  //     <Bounds X="330" Y="585" Width="3000" Height="390" />
  //   </ObjectInfo>
  //   <ObjectInfo>
  //     <TextObject>
  //       <Name>TEXT_2</Name>
  //       <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
  //       <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
  //       <LinkedObjectName />
  //       <Rotation>Rotation0</Rotation>
  //       <IsMirrored>False</IsMirrored>
  //       <IsVariable>False</IsVariable>
  //       <GroupID>-1</GroupID>
  //       <IsOutlined>False</IsOutlined>
  //       <HorizontalAlignment>Left</HorizontalAlignment>
  //       <VerticalAlignment>Top</VerticalAlignment>
  //       <TextFitMode>ShrinkToFit</TextFitMode>
  //       <UseFullFontHeight>True</UseFullFontHeight>
  //       <Verticalized>False</Verticalized>
  //       <StyledText>
  //         <Element>
  //           <String xml:space="preserve">${labelData.order}</String>
  //           <Attributes>
  //             <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />
  //             <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />
  //           </Attributes>
  //         </Element>
  //       </StyledText>
  //     </TextObject>
  //     <Bounds X="322" Y="900" Width="3180" Height="300" />
  //   </ObjectInfo>
  // </DieCutLabel>`;

  var labelXml = content;

  try {
    const printers = await dymo.getPrinters();

    if (printers.length === 0) {
      console.error("No Dymo printers found.");
      return;
    }
    await dymo.print("DYMO LabelWriter 450", labelXml);
  } catch (error) {
    toast.error("Error in Dymo Printer");
  }
};
export default DymoLabel;
