import { AxiosError } from "axios";
import HttpClient from "HttpClient";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoadButton from "../../Shared/Common/LoadButton";
import Splash from "../../Shared/Common/Pages/Splash";
import SecurityQuestions from "./SecurityQuestions/SecurityQuestions";
// import useLang from "Shared/hooks/useLanguage";
import useLang from "./../../Shared/hooks/useLanguage";

const NewPassword = () => {
  const { t } = useLang();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState(false);

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div>
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: 'url("/media/illustrations/sketchy-1/14.png")',
        }}
      >
        <div
          className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20"
          style={{ minHeight: "100vh" }}
        >
          <div className="w-lg-550px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
            <form className="form w-100" id="kt_login_signin_form">
              <a href="#" className="mb-12 d-flex justify-content-center">
                <img
                  alt="Logo"
                  src="/media/logos/logo.png"
                  className="h-45px"
                />
              </a>
              <div className="text-center mb-5">
                <h2 className="mb-3">{t("Setup New Password")}</h2>
                <div className="text-center mb-5">
                  <Alert variant="danger" className="p-1">
                    {t(
                      "Use 8 or more characters with a mix of letters (both cases), numbers, and symbols."
                    )}
                  </Alert>
                </div>
              </div>

              <div className="d-flex justify-content-between border rounded m-auto">
                <input
                  type={"password"}
                  className="form-control border-0"
                  placeholder={t("Password")}
                  minLength={8}
                />
                <button
                  type="button"
                  className="border-0 bg-transparent d-flex align-items-center"
                  onClick={() => toggleShowPassword()}
                >
                  <i
                    className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}
                  ></i>
                </button>
              </div>
              <div className="d-flex justify-content-between border rounded m-auto my-3">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control border-0"
                  placeholder={t("Repeat Password")}
                  minLength={8}
                />
                <button
                  type="button"
                  className="border-0 bg-transparent d-flex align-items-center"
                  onClick={() => toggleShowConfirmPassword()}
                >
                  <i
                    className={`bi ${showPassword ? "bi-eye-slash" : "bi-eye"}`}
                  ></i>
                </button>
              </div>

              <div className="text-center">
                <LoadButton
                  onClick
                  loading={loading}
                  btnText={t("Submit")}
                  loadingText="Submitting"
                  className="btn btn-primary b-0 w-100 mb-5 h-45px"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
