import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { assignFormValues } from "../../../Utils/Auth";
import { isJson } from "../../../Utils/Common/Requisition";
import useLang from "./../../hooks/useLanguage";

const PanelsCheckboxSpecimenSource = (props: any) => {
  const { t } = useLang();
  const location = useLocation();
  const [diagnosisCode, setDiagnosisCode] = useState<any>([]);
  const [checkedBoxes, setCheckedBoxes] = useState<any>([]);

  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }

  const convertPrefilledIcdDataToJson = () => {
    let inputValue: any[] = [];
    props.panels.map((inner: any) =>
      inner.specimenSourceOption.map(
        (option: any) => option.isSelected && inputValue.push(option)
      )
    );
    let newInputs = assignFormValues(
      props?.Inputs,
      // props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      inputValue,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    newInputs.then((res) => {
      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    });
  };

  useEffect(() => {
    convertPrefilledIcdDataToJson();
  }, [location?.state?.reqId && props.defaultValue]);

  const handleChange = (
    e: any,
    options: any,
    checked: boolean,
    panelName: string
  ) => {
    debugger;
    const panelIndex = props.panels.findIndex(
      (panel: any) => panel.panelID === options.panelID
    );

    // Find the index of the specimen option based on specimenTypeID
    const specimenIndex = props.panels[
      panelIndex
    ].specimenSourceOption.findIndex(
      (specimen: any) => specimen.specimenTypeID === options.specimenTypeID
    );

    if (specimenIndex !== -1)
      // Directly update the isSelected flag for the found specimen option
      props.panels[panelIndex].specimenSourceOption[specimenIndex].isSelected =
        e.target.checked;

    // props.panels.map((panel: any) => {
    //   if (panel.panelName === panelName) {
    //     panel.specimenSourceOption = panel.specimenSourceOption.map(
    //       (option: any) => {
    //         if (option.specimenTypeID === options.specimenTypeID) {
    //           option.isSelected = checked; // Update the isSelected flag
    //         }
    //         return option;
    //       }
    //     );
    //   }
    //   return panel;
    // });
    // if (checked) {
    let inputValue: any = [];
    let obj = {
      panelID:
        props.panels[panelIndex].specimenSourceOption[specimenIndex].panelID,
      specimenPreFix:
        props.panels[panelIndex].specimenSourceOption[specimenIndex]
          .specimenPreFix,
      specimenType:
        props.panels[panelIndex].specimenSourceOption[specimenIndex]
          .specimenType,
      specimenTypeID:
        props.panels[panelIndex].specimenSourceOption[specimenIndex]
          .specimenTypeID,
      panelName: panelName,
      isSelected: true,
    };
    inputValue = [...inputValue, obj];
    // props.panels?.map((inner: any) =>
    //   inner?.specimenSourceOption?.map(
    //     (option: any) => option?.isSelected && inputValue?.push(option)
    //   )
    // );

    let newInputs = assignFormValues(
      props?.Inputs,
      // props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      inputValue,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    newInputs.then((res) => {
      let infectiousDataCopy = JSON.parse(
        JSON.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props.ArrayReqId)
      ].sections = res;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    });
    //}
    if (!checked) {
      // setDiagnosisCode((preVal: any) => [
      //   ...preVal.filter(
      //     (items: any) => items?.icD10Code != options?.specimenPreFix
      //   ),
      // ]);
      setCheckedBoxes((preVal: any) => [
        ...preVal.filter((items: any) => items != items),
      ]);
      let result = isJson(props.defaultValue.defaultValue);
      let defaultValue = props.defaultValue.defaultValue;
      if (result) {
        defaultValue = JSON.parse(defaultValue);
      }
      let inputValue = defaultValue.filter(
        (checkedPanelsObj: any) =>
          checkedPanelsObj.Code != options?.specimenPreFix
      );

      let newInputs = assignFormValues(
        props?.Inputs,
        // props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs.then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData &&
          props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };
  const divElement = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (props.error && divElement.current) {
      divElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.error]);

  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div id={props?.name} ref={divElement} tabIndex={-1}></div>
      <div className={props?.sectionDisplayType}>
        <div className="d-flex flex-wrap ">
          {props?.panels?.map(
            (panels: any) =>
              panels?.isVisible && (
                <div
                  className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
                  key={panels.panelID}
                >
                  <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <h6>{panels?.panelName}</h6>
                    </div>
                  </div>
                  <div className="card-body py-md-4 py-3">
                    <div className="row">
                      {panels?.specimenSourceOption?.map((options: any) => (
                        <div
                          className="col-xl-6 col-lg-6 col-md-6 col-sm-6"
                          key={options.specimenPreFix}
                        >
                          <label className="form-check form-check-inline form-check-solid m-0 mb-2">
                            <input
                              className="form-check-input h-20px w-20px"
                              type="checkbox"
                              id={props.id}
                              name={props.label}
                              onChange={(e: any) => {
                                debugger;
                                handleChange(
                                  e,
                                  options,
                                  e.target.checked,
                                  panels.panelName
                                );
                              }}
                              checked={options.isSelected}
                              disabled={props?.disabled}
                            />
                            {options.specimenType}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default PanelsCheckboxSpecimenSource;
