import { useEffect, useRef, useState } from "react";
import { assignFormValues } from "../../../Utils/Auth";
import { isJson } from "../../../Utils/Common/Requisition";
import useLang from "./../../hooks/useLanguage";
import Checkbox from "./Checkbox";

const PanelsCheckBox = (props: any) => {
  const { t } = useLang();
  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }
  const [diagnosisCode, setDiagnosisCode] = useState<any>([]);
  const [checkedBoxes, setCheckedBoxes] = useState<any>([]);
  useEffect(() => {
    convertPrefilledIcdDataToJson();
  }, [props.defaultValue.defaultValue]);
  const divElement = useRef<HTMLDivElement | null>(null); // Initialize ref for div
  useEffect(() => {
    // Scroll to the div if props.error is present
    if (props.error && divElement.current) {
      divElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.error]);

  const convertPrefilledIcdDataToJson = () => {
    let result = isJson(props.defaultValue.defaultValue);
    let parsedIcd10Codes = props.defaultValue.defaultValue;
    if (result) {
      parsedIcd10Codes = JSON.parse(props.defaultValue.defaultValue);
    }
    let objectKeysConvertedToSmall =
      Array.isArray(parsedIcd10Codes) &&
      parsedIcd10Codes?.map((icd10Codeinfo: any) => {
        return {
          id: icd10Codeinfo.icd10id,
          icD10Code: icd10Codeinfo.Code,
          icD10CodeDescription: icd10Codeinfo.Description,
        };
      });

    //IN CASE IT WILL GIVE INFINE LOOP
    let newInputs = assignFormValues(
      props?.Inputs,
      // props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      parsedIcd10Codes,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );

    newInputs
      ?.then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );

        // Find the index of the relevant ArrayReqId
        const index = FindIndex(props?.infectiousData, props.ArrayReqId);

        // Check if the index is valid
        if (index !== -1) {
          infectiousDataCopy[index].sections = res; // Set sections if index is valid
          props?.setInfectiousData &&
            props?.setInfectiousData([...infectiousDataCopy]);
        } else {
          console.error(
            "Invalid index found. ArrayReqId not present in infectiousData."
          );
        }
      })
      .catch((error) => {
        console.error("Error in processing new inputs:", error);
      });

    let checboxIds =
      Array.isArray(parsedIcd10Codes) &&
      parsedIcd10Codes?.map((icd10Codeinfo: any) => icd10Codeinfo.Code);
    setDiagnosisCode(
      Array.isArray(objectKeysConvertedToSmall)
        ? objectKeysConvertedToSmall
        : []
    );
    setCheckedBoxes(Array.isArray(checboxIds) ? checboxIds : []);
  };
  //convertPrefilledIcdDataToJson();
  const handleChange = (
    id: string,
    code: string,
    description: string,
    checked: boolean
  ) => {
    props.setCheckboxState((prevState: any) => {
      const newState = prevState.map((item: any) => {
        if (item.testCode === code) {
          return { ...item, isChecked: checked };
        }
        return item;
      });
      return newState;
    });
    if (checked) {
      let obj = {
        id,
        icD10Code: code,
        icD10CodeDescription: description,
      };
      setDiagnosisCode((prev: any) => [...prev, obj]);
      setCheckedBoxes((prev: any) => [...prev, code]);
      let inputValue = [];
      inputValue.push({
        Code: code,
        Description: description,
        icd10id: id,
      });
      let defaultValue = props.defaultValue.defaultValue;
      if (isJson(defaultValue)) {
        defaultValue = JSON.parse(defaultValue);
      }
      if (defaultValue.length > 0) {
        inputValue = [...inputValue, ...defaultValue];
      }
      setDiagnosisCode([...inputValue]);
      assignFormValues(
        props?.Inputs,
        // props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      ).then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    } else {
      setDiagnosisCode((prev: any) =>
        prev.filter((item: any) => item.icD10Code !== code)
      );
      setCheckedBoxes((prev: any) => prev.filter((item: any) => item !== code));
      let defaultValue = props.defaultValue.defaultValue;
      if (isJson(defaultValue)) {
        defaultValue = JSON.parse(defaultValue);
      }
      let inputValue = defaultValue.filter(
        (checkedPanelsObj: any) => checkedPanelsObj.Code !== code
      );
      assignFormValues(
        props?.Inputs,
        // props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        inputValue,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      ).then((res) => {
        let infectiousDataCopy = JSON.parse(
          JSON.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };
  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div id={props?.name} ref={divElement} tabIndex={-1}></div>
      <div className={props?.sectionDisplayType}>
        <div className="d-flex flex-wrap">
          {props?.panels?.map(
            (panels: any) =>
              panels.isVisible && (
                <>
                  {panels?.testOptions && (
                    <div
                      className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
                    >
                      <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <h6>{panels?.panelName}</h6>
                        </div>
                      </div>
                      <div className="card-body py-md-4 py-3">
                        <div className="row">
                          {panels?.testOptions?.map(
                            (options: any, index: any) => (
                              <>
                                <Checkbox
                                  spanClassName="mb-2 mr-2"
                                  parentDivClassName="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                                  id={options?.testID}
                                  label={options.testName}
                                  testCode={options?.testCode}
                                  checked={
                                    props.sectionName ===
                                    "Diagnosis/ICD10 Codes"
                                      ? props.checkboxState.find(
                                          (item: any) =>
                                            item.testCode === options?.testCode
                                        )?.isChecked
                                      : options?.isSelected
                                  }
                                  onChange={(e: any) => {
                                    handleChange(
                                      options?.testID,
                                      options?.testCode,
                                      options?.testName,
                                      e.target.checked
                                    );
                                  }}
                                  sectionName={props.sectionName}
                                />
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* //Group icd */}
                  {panels?.icD10Group &&
                    panels.icD10Group.map((options: any, index: any) => (
                      <div
                        className={`card shadow-sm mb-3 rounded border border-warning ${props?.displayType}`}
                      >
                        <div className="card-header d-flex justify-content-between align-items-center rounded bg-light-warning">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <h6>{options.group}</h6>
                          </div>
                        </div>
                        <div className="card-body py-md-4 py-3">
                          <div className="row">
                            <Checkbox
                              spanClassName="mb-2 mr-2"
                              parentDivClassName="col-xl-12 col-lg-12 col-md-12 col-sm-12"
                              id={options?.icD10Id}
                              label={options?.icD10Description}
                              testCode={options?.icD10Code}
                              checked={options?.isSelected}
                              onChange={(e: any) => {
                                handleChange(
                                  options?.icD10Id,
                                  options?.icD10Code,
                                  options?.icD10Description,
                                  e.target.checked
                                );
                              }}
                              sectionName={props.sectionName}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default PanelsCheckBox;
