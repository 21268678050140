import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useLang from "Shared/hooks/useLanguage";
import UseDrugAllergiesAutoComplete from "../../../Shared/hooks/Requisition/UseDrugAllergiesAutoComplete";
import { assignFormValues } from "../../../Utils/Auth";
import MuiSkeleton from "../MuiSkeleton";

const CheckBoxList = (props: any) => {
  const divElement = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const { t } = useLang();

  useEffect(() => {
    if (props.error && divElement.current) {
      divElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.error]);

  useEffect(() => {
    EditDrugAllergies();
  }, [props.defaultValue]);

  const [selectedDrugAllergies, setSelectedDrugAllergiesBox] = useState<any>(
    props?.defaultValue || []
  );
  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>(
    props.defaultValue
      ? Object.fromEntries(
          props?.defaultValue.map((option: any) => [option.id, true])
        )
      : Object.fromEntries(
          props?.RadioOptions.map((option: any) => [option.id, false])
        )
  );
  const inputSearchRef = useRef<HTMLInputElement>(null);

  const {
    searchedValue,
    setSearchedValue,
    suggestions,
    setSuggestions,
    handleChange,
    handleKeyDown,
  } = UseDrugAllergiesAutoComplete(inputSearchRef);

  const EditDrugAllergies = () => {
    if (props.sysytemFieldName === "DrugAllergies") {
      assignFormValues(
        props.Inputs,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        props.defaultValue,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
    }
  };
  const removeDrugAllergy = (id: number, label: any) => {
    let inputsCopy = [...props?.Inputs];
    let index = props?.index;
    if (index) {
      let indexDrugs = inputsCopy[index]?.fields?.findIndex(
        (i: any) => i?.systemFieldName === "DrugAllergies"
      );
      inputsCopy[index].fields[indexDrugs].defaultValue = inputsCopy[
        index
      ].fields[indexDrugs]?.defaultValue?.filter(
        (innerDrug: any) => innerDrug.label !== label
      );
    }
    setSelectedDrugAllergiesBox((prevAllergies: any) => {
      const updatedArray = [...prevAllergies];
      const indexToRemove = updatedArray.findIndex(
        (item: any) => item.id === id
      );
      if (indexToRemove !== -1) {
        updatedArray.splice(indexToRemove, 1);
      }
      setCheckboxState((prevState) => {
        const newCheckboxState = { ...prevState };
        newCheckboxState[id] = false;
        return newCheckboxState;
      });
      const modifiedEvent = {
        target: {
          checked: false,
          id,
        },
      };
      props.onChange(modifiedEvent, id, label);
      return updatedArray;
    });
  };

  useEffect(() => {
    if (!location?.state?.reqId) {
      setCheckboxState({});
      setSelectedDrugAllergiesBox([]);
    }
  }, [props?.apiCallCondition]);

  // Function to add a new drug allergy
  const addNewDrugAllergy = () => {
    if (
      searchedValue &&
      !selectedDrugAllergies.some((drug: any) => drug.label === searchedValue)
    ) {
      const newDrug = {
        id: Date.now(), //generating new id for new requisition
        label: searchedValue,
        value: searchedValue.toLowerCase().replace(/ /g, "_"),
      };
      // Add the new drug allergy to the selected list
      setSelectedDrugAllergiesBox((prev: any) => [...prev, newDrug]);
      setCheckboxState((prevState) => ({
        ...prevState,
        [newDrug.id]: true,
      }));
      // Trigger the onChange event to propagate the new allergy to the parent component
      const modifiedEvent = {
        target: {
          checked: true,
          id: newDrug.id,
        },
      };
      props.onChange(modifiedEvent, newDrug.value, newDrug.label);
      // Reset the input field after adding the drug allergy
      setSearchedValue("");
    }
  };
  // Function to handle click on suggestion
  const handleSuggestionClick = (suggestion: any) => {
    if (
      !selectedDrugAllergies.some(
        (drug: any) => drug.label === suggestion.label
      )
    ) {
      setSelectedDrugAllergiesBox((prev: any) => [
        ...prev,
        { id: suggestion.value, label: suggestion.label },
      ]);
      setCheckboxState((prevState) => ({
        ...prevState,
        [suggestion.value]: true,
      }));

      const modifiedEvent = {
        target: {
          checked: true,
          id: suggestion.value,
        },
      };
      props.onChange(modifiedEvent, suggestion.value, suggestion.label);
      setSearchedValue("");
      setSuggestions([]);
    }
  };

  console.log(selectedDrugAllergies, "selectedDrugAllergies");
  return (
    <>
      <div
        className={
          props?.parentDivClassName
            ? `${props?.parentDivClassName}`
            : "col-lg-6 col-md-6 col-sm-12 mb-3"
        }
        ref={divElement}
      >
        {props.sysytemFieldName === "DrugAllergies" ? null : (
          <h6 className="fw-600 mb-5">{t(props?.displayFieldName)}</h6>
        )}
        <div className="row">
          {props?.RadioOptions.map(
            (options: any) =>
              options?.isVisable && (
                <div className="col-lg-3 col-md-6 col-6 py-1" key={options.id}>
                  <div className="form__group form__group--checkbox mb-3">
                    <label
                      className={
                        props?.labelClassName
                          ? `${props?.labelClassName} fw-400 text-break`
                          : "form-check form-check-inline form-check-solid m-0 fw-400 text-break"
                      }
                    >
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        id={options.id}
                        name={options.name}
                        value={options.value}
                        onChange={(e: any) => {
                          // setEvent(e);
                          setCheckboxState((prevState) => ({
                            ...prevState,
                            [options.id]: e.target.checked,
                          }));
                          const checked = e.target.checked;
                          const id = e.target.id;
                          props.onChange(e, options.value, options.label);
                          let obj = {
                            id: e.target.id,
                            label: options.label,
                          };
                          if (checked) {
                            setSelectedDrugAllergiesBox([
                              ...selectedDrugAllergies,
                              obj,
                            ]);
                          } else {
                            setSelectedDrugAllergiesBox((preVal: any) =>
                              preVal.filter(
                                (selectedDrugAllergiesInfo: any) =>
                                  selectedDrugAllergiesInfo.id !== id
                              )
                            );
                          }
                        }}
                        defaultChecked={options?.isSelectedDefault}
                        checked={checkboxState[options.id]}
                      />
                      {props?.loading ? (
                        <MuiSkeleton height={22} />
                      ) : (
                        <span className={props?.spanClassName || ""}>
                          {t(options.label)}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              )
          )}
        </div>

        {props?.sectionId === 9 && (
          <>
            <div className="d-flex align-items-start gap-2 mt-2">
              <div className="w-100 w-md-300px position-relative">
                <label className="mb-1 fw-500">{t("Others")}</label>
                <div className="d-flex position-relative">
                  <input
                    type="text"
                    name="DrugOthers"
                    placeholder={t("Allergies / Drug Allergies")}
                    className="form-control bg-transparent"
                    value={searchedValue}
                    onChange={(e) => {
                      setSearchedValue(e.target.value);
                      handleChange(e); // Triggers search for suggestions
                    }}
                    onKeyDown={handleKeyDown}
                    ref={inputSearchRef}
                  />
                  <button
                    className="btn btn-primary justify-content-center d-flex px-2"
                    onClick={() => {
                      if (
                        searchedValue &&
                        !selectedDrugAllergies.some(
                          (drug: any) => drug.label === searchedValue
                        )
                      ) {
                        addNewDrugAllergy();
                      }
                    }}
                  >
                    <i className="bi bi-plus" style={{ fontSize: "25px" }}></i>
                  </button>
                </div>
                {suggestions.length > 0 && searchedValue && (
                  <div
                    className="shadow-sm mt-2 rounded-2"
                    style={{
                      maxHeight: "300px",
                      overflowY: "auto",
                    }}
                  >
                    {suggestions.map((suggestion: any, index: number) => (
                      <div
                        key={index}
                        className="py-1 bg-hover-light-primary px-3"
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion.label}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex flex-wrap gap-2 mt-4">
              {selectedDrugAllergies.map((selectedDrugAllergiesInfo: any) => (
                <div
                  key={selectedDrugAllergiesInfo.id}
                  className="d-flex badge badge-secondary px-2 fw-500 gap-2 align-items-center pt-2"
                >
                  <i
                    className="bi bi-x-lg fs-7"
                    onClick={() =>
                      removeDrugAllergy(
                        selectedDrugAllergiesInfo.id,
                        selectedDrugAllergiesInfo.label
                      )
                    }
                  ></i>
                  <span id={selectedDrugAllergiesInfo.id}>
                    {t(selectedDrugAllergiesInfo.label)}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
    </>
  );
};

export default CheckBoxList;
