import { Collapse } from "@mui/material";
import { AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Select from "react-select";
import { toast } from "react-toastify";
import InsuranceService from "../../../../Services/InsuranceService/InsuranceService";
import LoadButton from "../../../../Shared/Common/LoadButton";
import { useCourierContext } from "../../../../Shared/CourierContext";
import useLang from "Shared/hooks/useLanguage";
import { styles } from "../../../../Utils/Common";

function AddNewPickup(props: { modalShow: boolean; setModalShow: any }) {

  const { t } = useLang()

  const { modalShow, setModalShow } = props;
  const { courierName, loadData } = useCourierContext();
  const [pickupStartTime, setPickUpStartTime] = useState<any>(null);
  const [pickupEndTime, setPickUpEndTime] = useState<any>(null);
  const [pickupError, setPickupError] = useState(false);
  const [dropdownStates, setDropdownStates] = useState([]);
  const handlePickupStart = (value: string) => {
    let pickupStartTimeInSecs = convertTimeToSeconds(value);
    setPickUpStartTime(+pickupStartTimeInSecs);
  };
  const handlePickupEnd = (value: string) => {
    let pickupEndTimeInSecs = convertTimeToSeconds(value);
    setPickUpEndTime(+pickupEndTimeInSecs);
  };

  const convertTimeToSeconds = (timeString: string) => {
    const timeMoment = moment(timeString, "HH:mm");

    const totalSeconds = timeMoment.hours() * 3600 + timeMoment.minutes() * 60;
    return totalSeconds;
  };

  useEffect(() => {
    if (pickupStartTime && pickupEndTime) {
      if (pickupEndTime < pickupStartTime) {
        setPickupError(true);
      } else {
        setPickupError(false);
      }
    }
  }, [pickupEndTime, pickupStartTime]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialSchedulePickup = {
    courierName: courierName,
    contactName: "",
    companyName: "",
    address: "",
    address2: "",
    city: "",
    stateId: "",
    zipCode: "",
    phoneNumber: "",
    pickupDate: null,
    startPickupTime: null,
    endPickupTime: null,
    packagesCount: "",
    remarks: "",
    dispatchConfirmationNo: "",
    location: "",
  };
  const [schedulePickup, setSchedulePickup] = useState(initialSchedulePickup);
  const {
    contactName,
    companyName,
    address,
    address2,
    city,
    stateId,
    zipCode,
    phoneNumber,
    pickupDate,
    startPickupTime,
    endPickupTime,
    packagesCount,
    remarks,
  } = schedulePickup;

  useEffect(() => {
    setSchedulePickup({
      ...schedulePickup,
      courierName,
    });
  }, [courierName]);

  const addSchedulePickup = (e: any) => {
    setIsSubmitting(true);
    InsuranceService.SavePickup({
      ...schedulePickup,
      startPickupTime: `${pickupDate}T${startPickupTime}`,
      endPickupTime: `${pickupDate}T${endPickupTime}`,
      packagesCount: parseInt(packagesCount)
    })
      .then((res: any) => {
        if (res?.data?.httpStatusCode === 200) {
          toast.success(res?.data?.message);
          reset();
          setIsSubmitting(false);
          loadData()
        }
      })
      .catch((err: any) => {
        console.log(err, "err while creating Insurance Provide Assigment");
        setIsSubmitting(false);
      });
  };
  // React hook form start
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const handleKeyPressed = (e: any) => {
    const keyCode = e.charCode || e.keyCode;
    const allowedKeyCodes = [8, 9, 37, 38, 39, 40, 46]; // 8: backspace, 9: tab, 37: left arrow, 38: up arrow, 39: right arrow, 40: down arrow, 40: delete
    if ((keyCode < 48 || keyCode > 57) && !allowedKeyCodes.includes(keyCode)) {
      e.preventDefault();
    }
  };

  const onInputChange = (event: any) => {
    const { name, value } = event.target;
    setSchedulePickup({
      ...schedulePickup,
      [name]: value,
    });
    setValue(name, value);
    clearErrors(name);
  };

  const handleChange = (e: any, name: string) => {
    setSchedulePickup((prev) => ({
      ...prev,
      [name]: e.value,
    }));
    setValue(name, e.value);
    clearErrors(name);
  };
  const loadStatesLookUp = () => {
    InsuranceService.GetStatesLookup()
      .then((res: AxiosResponse) => {
        setDropdownStates(res?.data);
      })
      .catch((err: any) => {
        console.trace(err, "err");
      });
  };

  const handleEnterPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  // React hook form end
  const reset = () => {
    setModalShow(false);
    setSchedulePickup(initialSchedulePickup);
    clearErrors();
  };
  useEffect(() => {
    loadStatesLookUp();
  }, []);
  return (
    <>
      <Collapse in={modalShow}>
        <form
          onSubmit={handleSubmit(addSchedulePickup)}
          onKeyDown={handleEnterPress}
        >
          <div id="ModalCollapse" className="card mb-5">
            <div className="align-items-center bg-light-warning card-header minh-42px d-flex justify-content-center justify-content-sm-between gap-1">
              <h4 className="m-1">{t("Schedule a Pickup")}</h4>
              <div className="d-flex align-items-center gap-2 gap-lg-3">
                <button
                  className="btn btn-secondary btn-sm fw-bold "
                  aria-controls="SearchCollapse"
                  aria-expanded="true"
                  onClick={() => reset()}
                  type="button"
                >
                  <span>
                    <span>{t("Cancel")}</span>
                  </span>
                </button>
                <LoadButton
                  className="btn btn-sm fw-bold btn-primary"
                  // loading={isSubmitting}
                  btnText="Save"
                  loadingText="Saving"
                />
              </div>
            </div>

            <div id="form-search" className=" card-body py-2 py-md-3">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("Contact Name")}</label>
                    <input
                      {...register("contactName", { required: true })}
                      type="text"
                      name="contactName"
                      onChange={(e) => onInputChange(e)}
                      className={`form-control
                        "bg-transparent"
                      `}
                      placeholder="Contact Name"
                      value={contactName}
                    />
                    {errors.contactName && (
                      <p className="text-danger px-2">
                        {t("Please enter the Contact Name.")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("Company Name")}</label>
                    <input
                      {...register("companyName", { required: true })}
                      type="text"
                      name="companyName"
                      onChange={(e) => onInputChange(e)}
                      className="form-control bg-transparent"
                      placeholder="Company Name"
                      value={companyName}
                    />
                    {errors.companyName && (
                      <p className="text-danger px-2">
                        {t("Please enter the Company Name")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("Address")}</label>
                    <input
                      {...register("address", {
                        required: true,
                      })}
                      type="text"
                      name="address"
                      onChange={(e) => onInputChange(e)}
                      className="form-control bg-transparent"
                      placeholder="Address"
                      value={address}
                    />
                    {errors.address && (
                      <p className="text-danger px-2">
                        {t("Please enter the address")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="mb-2 fw-500">{t("Address 2")}</label>
                    <input
                      type="text"
                      name="address2"
                      onChange={(e) => onInputChange(e)}
                      className="form-control bg-transparent"
                      placeholder="address2"
                      value={address2}
                    />
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("City")}</label>
                    <input
                      {...register("city", {
                        required: true,
                      })}
                      type="text"
                      name="city"
                      onChange={(e) => onInputChange(e)}
                      className="form-control bg-transparent"
                      placeholder="City"
                      value={city as any}
                    />
                    {errors.city && (
                      <p className="text-danger px-2">
                        {t("Please enter the city name")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="mb-2 fw-500 required">{t("State")}</label>
                    <Select
                  menuPortalTarget={document.body}

                      {...register("stateId", { required: true })}
                      options={dropdownStates}
                      theme={(theme: any) => styles(theme)}
                      placeholder="State"
                      name="stateId"
                      value={dropdownStates?.filter(
                        (item: any) => item.value == stateId
                      )}
                      onChange={(event: any) => {
                        handleChange(event, "stateId");
                      }}
                    />
                    {errors.stateId && (
                      <p className="text-danger px-2">
                        {t("Please select the state")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("Zip Code")}</label>
                    <input
                      {...register("zipCode", {
                        required: true,
                        pattern: {
                          value: /^\d{5}$/,
                          message: "Issue",
                        },
                      })}
                      type="text"
                      name="zipCode"
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => handleKeyPressed(e)}
                      className="form-control bg-transparent"
                      placeholder="Zip Code"
                      value={zipCode}
                      maxLength={5}
                      inputMode="numeric"
                    />
                    {errors.zipCode && (
                      <p className="text-danger px-2">
                        {t("Zip Code must be 5 digits.")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("Phone Number")}</label>
                    <InputMask
                      mask="(999) 999-9999"
                      value={phoneNumber}
                      {...register("phoneNumber", {
                        required: "Phone number is required",
                        pattern: {
                          value: /^\(\d{3}\) \d{3}-\d{4}$/,
                          message: "Phone number must be 10 digits",
                        },
                      })}
                      type="tel"
                      name="phoneNumber"
                      onChange={onInputChange}
                      onKeyDown={handleKeyPressed}
                      className={`form-control bg-transparent ${errors.phoneNumber ? "is-invalid" : ""
                        }`}
                      placeholder="(999) 999-9999"
                      inputMode="numeric"
                    />
                    <>{(inputProps: any) => <input {...inputProps} />}</>

                    {errors.phoneNumber && (
                      <p className="text-danger px-2">
                        {t("Phone number must be 10 digits.")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">{t("Pickup Date")}</label>
                    <input
                      {...register("pickupDate", {
                        required: true,
                      })}
                      type="date"
                      name="pickupDate"
                      onChange={(e) => onInputChange(e)}
                      className="form-control bg-transparent"
                      placeholder="Pickup Date"
                      value={pickupDate ?? ""}
                    />
                    {errors.pickupDate && (
                      <p className="text-danger px-2">
                        {t("Please enter the Pickup Date")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">
                      {t("Start Pickup Time")}
                    </label>
                    <input
                      {...register("startPickupTime", {
                        required: true,
                      })}
                      type="time"
                      name="startPickupTime"
                      onChange={(e) => {
                        handlePickupStart(e.target.value);
                        onInputChange(e);
                      }}
                      className="form-control bg-transparent"
                      placeholder="Start Pickup Time"
                      value={startPickupTime ?? ""}
                    />
                    {errors.startPickupTime && (
                      <p className="text-danger px-2">
                        {t("Please enter the Start Pickup Time")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">
                      {t("End Pickup Time")}
                    </label>
                    <input
                      {...register("endPickupTime", {
                        required: true,
                      })}
                      type="time"
                      name="endPickupTime"
                      onChange={(e) => {
                        handlePickupEnd(e.target.value);
                        onInputChange(e);
                      }}
                      className={`form-control `}
                      placeholder="End Pickup Time"
                      value={endPickupTime ?? ""}
                    />
                    {errors.endPickupTime && (
                      <p className="text-danger px-2">
                        {t("Please enter the end Pickup Time")}
                      </p>
                    )}
                    {pickupError ? (
                      <span className="text-danger p-2">
                        {t("End time should be greater than start time")}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="required mb-2 fw-500">
                      {t("Packages Count")}
                    </label>
                    <input
                      {...register("packagesCount", {
                        required: true,
                      })}
                      type="text"
                      name="packagesCount"
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => handleKeyPressed(e)}
                      className="form-control bg-transparent"
                      placeholder="Packages Count"
                      value={packagesCount}
                    />
                    {errors.packagesCount && (
                      <p className="text-danger px-2">
                        {t("Please enter the Packages Count")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                  <div className="fv-row mb-4">
                    <label className="mb-2 fw-500">{t("Remarks")}</label>
                    <input
                      type="text"
                      name="remarks"
                      onChange={(e) => onInputChange(e)}
                      className="form-control bg-transparent"
                      placeholder="Packages Count"
                      value={remarks}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Collapse>
    </>
  );
}

export default AddNewPickup;
