import { AxiosResponse } from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import ReadMore from "../Pages/Requisition/SingleRequisition/ReadMore";
import {
  setRequisitionData,
  setSelectedRequisitionData,
} from "../Redux/Actions/Pages/Requisition";
import Commonservice from "../Services/CommonService";
import RequisitionType from "../Services/Requisition/RequisitionTypeService";
import Checkbox from "../Shared/Common/Input/Checkbox";
import Input from "../Shared/Common/Input/Input";
import Radio2 from "../Shared/Common/Input/Radio2";
import ReactSelect from "../Shared/Common/Input/ReactSelect";
import { CrossIcon } from "../Shared/Icons";
import {
  assignFormValues,
  assignFormValuesForMedicalNecessity,
} from "../Utils/Auth";
import { showDep, styles } from "../Utils/Common";
import {
  isJson,
  setDropDownValue,
  setJSONDataFormat,
  setPatientValues,
  showDepRepeatFields,
} from "../Utils/Common/Requisition";
import AutoComplete from "./AutoComplete";
import ActiveMedicationListCheckbox from "./Common/Input/ActiveMedicationListCheckbox";
import AdditionalTest from "./Common/Input/AdditionalTest";
import CheckBoxList from "./Common/Input/CheckBoxList";
import ControlAutoDynamicComplete from "./Common/Input/ControlDynamicAutocomplete";
import ControlDynamicDropDown from "./Common/Input/ControlDynamicDropDown";
import DatePicker from "./Common/Input/DatePicker";
import DynamicAutoComplete from "./Common/Input/DynamicAutoComplete";
import FileUpload from "./Common/Input/FileUpload";
import MultipleHeaderSelectable from "./Common/Input/MultipleSelectableHeader";
import PanelsCheckBox from "./Common/Input/PanelsCheckBox";
import PanelsCheckboxSelected from "./Common/Input/PanelsCheckboxSelected";
import PanelsCheckboxSpecimenSource from "./Common/Input/PanelsCheckboxSpecimenSource";
import RawText from "./Common/Input/RawText";
import ServerSideDynamicDropDown from "./Common/Input/ServerSideDynamicDropDown";
import SignPad from "./Common/Input/SignPad";
import Switch from "./Common/Input/Switch";
import TextArea from "./Common/Input/Textarea";
import TimeInput from "./Common/Input/Time";
import ToxTestingOption from "./Common/Input/ToxTestingOption";
import useLang from "./hooks/useLanguage";
import POCResults from "./POCResults";
import RepeatInputs from "./RepeatInputs";
import ToxTestingOptionCheckbox from "./TestingOptionCheckbox";
import HeaderSelectableOnlyPanel from "./Common/Input/HeaderSelectableOnlyPanel";
import GenericPhoneNumberInput from "./Common/Input/GenericPhoneNumberType";

const DynamicFormInputs = (props: any) => {
  const {
    label,
    uiType,
    visible,
    required,
    options,
    displayType,
    RadioOptions,
    index,
    setShowHideFields,
    sysytemFieldName,
    isDependent,
    dependenceyControls,
    depControlIndex,
    dependencyAction,
    isDependency,
    depfield,
    removeUi,
    depName,
    Inputs,
    recursiveDependencyControls,
    showRecursiveDep,
    fieldIndex,
    repeatFieldIndex,
    repeatDependencySectionIndex,
    repeatDepFieldIndex,
    fieldLength,
    sectionName,
    repeatFieldSection,
    defaultValue,
    isDependencyRepeatFields,
    disabled,
    enableRule,
    ArrayReqId,
    ...inputProps
  } = props;

  const { t } = useLang();
  const ref = useRef<any>(null);
  const [apiCallCondition, setApCallCondition] = useState<boolean>(false);
  const [selectedMedications, setSelectedMedications] = useState<any>([]);
  const location = useLocation();

  const inputElement = useRef<any>(0);
  const dispatch = useDispatch();

  interface LooseObject {
    [key: string]: any;
  }

  const _setState = (key?: any, value?: string | boolean) => {
    const obj: LooseObject = {};
    if (key) {
      obj[key] = value;
      obj.sectionName = props?.section?.sectionName;
    }
    props?.setFormState({
      ...props?.formState,
      ...obj,
    });
  };

  const handleChange = async (
    name: string,
    value: string,
    id: string,
    depfield?: any,
    searchID?: any,
    controlId?: any,
    index?: any,
    sectionName?: any,
    fieldIndex?: any,
    isDependency?: any,
    infectiousInputs?: boolean
  ) => {
    if (sectionName === "Billing Information" || sectionName === "Facility") {
      if (name === "BillingType") {
        await loadReqSec();
      }
    }
    if (isDependency) {
      Inputs[index].dependencyControls[depControlIndex].dependecyFields[
        fieldIndex
      ].defaultValue = value;
    }
    if (!isDependency) {
      Inputs[index].fields[fieldIndex].defaultValue = value;
    }
    let obj: any = {};
    obj[name] = value;
    props?.setFormData({
      ...props?.formData,
      ...obj,
      name: name,
      id: id,
      value: value,
      [name]: value,
      depfield: depfield,
      searchID: searchID,
      preId: props?.formData?.id,
      preName: props?.formData?.name,
      controlId: controlId,
      sectionName: sectionName,
    });

    let modifiedInputsArray = await showDep(
      Inputs,
      index,
      id,
      name,
      depfield,
      searchID,
      ArrayReqId
    );
    if (!infectiousInputs) {
      props?.setInputs && props?.setInputs(modifiedInputsArray);
    } else {
      const infectiousDataCopy = [...props?.infectiousData];
      infectiousDataCopy[FindIndex(infectiousDataCopy, ArrayReqId)].sections =
        modifiedInputsArray;
      props?.setInfectiousData &&
        props?.setInfectiousData([...infectiousDataCopy]);
    }
    return modifiedInputsArray;
  };

  const handleChangeRepeatFields = async (
    name: string,
    id: string,
    depfield?: any,
    searchID?: any,
    controlId?: any,
    index?: any,
    sectionName?: any,
    fieldIndex?: any
  ) => {
    let modifiedInputsArray = await showDepRepeatFields(
      Inputs,
      index,
      id,
      name,
      depfield,
      searchID,
      fieldIndex,
      controlId
    );
    console.log(name, "nameuityiy986");
    props?.setInputs && props?.setInputs(modifiedInputsArray);
    if (sectionName === "Billing Information" || sectionName === "Facility") {
      if (!location?.state?.reqId) {
        if (name === "BillingType") {
          if (fieldIndex === 0) {
            await loadReqSec();
          }
        }
      }
      // if (location?.state?.reqId) {
      //   let obj = {
      //     facilityId: localStorage.getItem("facilityID"),
      //     insuranceTypeId: localStorage.getItem("insurnceID"),
      //     pageId: 6,
      //     requisitionId: location?.state?.reqId,
      //     requisitionOrderId: location.state.orderid,
      //   };
      //   if (name === "BillingType") LoadRequisitionSection(obj);
      // }
    }
  };

  // const LoadRequisitionSection = useCallback((obj: any) => {
  //   RequisitionType?.LoadReqSectionByFacilityIDandInsuranceId(obj)
  //     .then((res: AxiosResponse) => {
  //       res.data.forEach((requisitionNameLevel: any) => {
  //         requisitionNameLevel.sections.forEach((sectionsLevel: any) => {
  //           sectionsLevel.fields.forEach((fieldsLevel: any) => {
  //             fieldsLevel.singleRequsition = true;
  //           });
  //         });
  //       });
  //       props.setInfectiousData(res.data);
  //       if (location?.state?.reqId) {
  //         let objToSend: any;
  //         let ReqIdArray: any = [];
  //         let ReqNameArray: any = [];
  //         res.data.map((item: any) => {
  //           ReqIdArray.push(item?.reqId);
  //           ReqNameArray.push(item?.requistionName);
  //         });
  //         objToSend = {
  //           requsitionId: ReqIdArray,
  //           requisitionName: ReqNameArray,
  //         };
  //         dispatch(setSelectedRequisitionData(objToSend));
  //       }
  //       dispatch(
  //         setRequisitionData({
  //           reqRequestData: res?.data,
  //         })
  //       );

  //       ref.current.scrollIntoView({ behavior: "smooth" });
  //     })
  //     .catch((err: any) => {
  //       console.error(err);
  //     });
  // }, []);

  const loadReqSec = async () => {
    window.dispatchEvent(new Event("storage"));
    const getValuesFromLocalStorage = () => {
      return {
        facilityId: localStorage.getItem("facilityID"),
        insuranceTypeId: localStorage.getItem("insurnceID"),
        pageId: 6,
      };
    };
    const obj = getValuesFromLocalStorage();
    if (!obj.facilityId || !obj.insuranceTypeId) {
      setTimeout(loadReqSec, 100);
      return;
    }
    await RequisitionType?.LoadReqSectionByFacilityIDandInsuranceId(obj)
      .then((res: AxiosResponse) => {
        res.data.forEach((requisitionNameLevel: any) => {
          requisitionNameLevel.sections.forEach((sectionsLevel: any) => {
            sectionsLevel.fields.forEach((fieldsLevel: any) => {
              fieldsLevel.singleRequsition = true;
            });
          });
        });
        props.setInfectiousData(res.data);
        props?.setInfectiousDataInputsForValidation(res?.data);
        dispatch(
          setRequisitionData({
            reqRequestData: res?.data,
          })
        );
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const inputElementDropdown = useRef(
    uiType === "DropDown" && (visible || isDependent) ? sysytemFieldName : ""
  );

  useEffect(() => {
    if (!inputElementDropdown.current) return;
    if (
      props?.errorFocussedInput === inputElementDropdown.current ||
      props?.errorFocussedInput === inputElementDropdown.current.id
    ) {
      inputElementDropdown.current.focus();
    }
  }, [props?.errorFocussedInput]);

  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }

  const handleServerSideDropdownOnChange = async (
    e: any,
    isAutoPopulateChangeTrigger: boolean = false
  ) => {
    let newInputs = isAutoPopulateChangeTrigger
      ? await assignFormValues(
          Inputs,
          // dependenceyControls,
          index,
          depControlIndex,
          e.fieldIndex,
          e.value,
          isDependency,
          repeatFieldSection,
          isDependencyRepeatFields,
          repeatFieldIndex,
          repeatDependencySectionIndex,
          repeatDepFieldIndex,
          e.label,
          props?.setInputs
        )
      : await assignFormValues(
          Inputs,
          // dependenceyControls,
          index,
          depControlIndex,
          fieldIndex,
          e.value,
          isDependency,
          repeatFieldSection,
          isDependencyRepeatFields,
          repeatFieldIndex,
          repeatDependencySectionIndex,
          repeatDepFieldIndex,
          e.label,
          props?.setInputs
        );
    if (!location?.state?.reqId) {
      props?.setInputs(newInputs);
    }

    let newData = null;
    if (!props?.repeatInputs) {
      newData = await handleChange(
        e.name,
        e.value,
        e.id,
        depfield,
        props?.searchID,
        props?.controlId,
        props?.index,
        props?.section?.sectionName,
        isAutoPopulateChangeTrigger ? e.fieldIndex : fieldIndex,
        isDependency,
        props?.infectiousInputs
      );
    } else {
      handleChangeRepeatFields(
        e.name,
        e.id,
        depfield,
        props?.searchID,
        props?.controlId,
        props?.index,
        sectionName,
        isAutoPopulateChangeTrigger ? e.fieldIndex : fieldIndex
      );
    }
    _setState(props?.sysytemFieldName, e.value);
    initialApiLoad(newData);
  };

  const initialApiLoad = async (newData: any) => {
    if ((props?.depfield ?? props?.field)?.autoCompleteOption) {
      let parsedAutoCompleteData: any = JSON.parse(
        (props?.depfield ?? props?.field)?.autoCompleteOption
      );

      let payload = {
        systemFieldName:
          parsedAutoCompleteData.DependentControls[0].systemFieldName,
        jsonFieldNames: "",
      };

      let requestBody = parsedAutoCompleteData?.RequestBody;

      newData.map((section: any) => {
        section.fields.map((field: any) => {
          if (requestBody.hasOwnProperty(field.systemFieldName))
            requestBody[field.systemFieldName] = field.defaultValue;
        });
        section.dependencyControls.map((options: any) => {
          options.dependecyFields.map((field: any) => {
            if (
              requestBody.hasOwnProperty(field.systemFieldName) &&
              !field.displayType.includes("d-none")
            )
              requestBody[field.systemFieldName] = field.defaultValue;
          });
        });
      });

      if (parsedAutoCompleteData.Uri) {
        payload.jsonFieldNames = JSON.stringify(requestBody);

        const response = await Commonservice.makeApiCallForDropDown(
          parsedAutoCompleteData.Uri,
          payload
        );

        let updatedData = assignOptionsToDropDown(
          response.data.responseModel,
          parsedAutoCompleteData,
          newData
        );
        props.setInputs(updatedData);
      }
    }
  };

  const assignOptionsToDropDown = (
    _options: any,
    parsedAutoCompleteData: any,
    newData: any
  ) => {
    const updatedFields = newData.map((section: any) => {
      section.fields.map((field: any) => {
        if (
          field.systemFieldName ===
          parsedAutoCompleteData.DependentControls[0].systemFieldName
        ) {
          field.options = _options;
          field.defaultValue = "";
        }
      });

      section.dependencyControls.map((options: any) => {
        options.dependecyFields.map((field: any) => {
          if (
            field.systemFieldName ===
            parsedAutoCompleteData.DependentControls[0].systemFieldName
          ) {
            field.options = _options;
            field.defaultValue = "";
          }
        });
      });

      return section;
    });

    return updatedFields;
  };

  let touched = false;
  useEffect(() => {
    if (sysytemFieldName === "CollectorID" && !touched) {
      let obj = RadioOptions?.find((i: any) => i?.value == defaultValue);
      assignFormValues(
        Inputs,
        // dependenceyControls,
        index,
        depControlIndex,
        fieldIndex,
        obj?.value,
        isDependency,
        repeatFieldSection,
        isDependencyRepeatFields,
        repeatFieldIndex,
        repeatDependencySectionIndex,
        repeatDepFieldIndex,
        obj?.label,
        props?.setInputs
      );
    }
  }, [location?.state?.reqId]);

  const handleApiCall = () => {
    setApCallCondition(!apiCallCondition);
  };

  useEffect(() => {
    window.addEventListener("storage", handleApiCall);
    return () => {
      window.removeEventListener("storage", handleApiCall);
    };
  }, [apiCallCondition]);

  console.log(RadioOptions, "RadioOptions");

  return (
    <>
      {uiType === "DropDown" && (visible || isDependent) ? (
        <>
          <div
            className={
              props?.displayType
                ? props?.displayType
                : "col-lg-6 col-md-6 col-sm-12 mb-4"
            }
          >
            {" "}
            <div id={sysytemFieldName} ref={inputElementDropdown} tabIndex={-1}>
              {" "}
            </div>
            <label
              className={required ? "required mb-2 fw-500" : "mb-2 fw-500"}
            >
              {t(label)}
            </label>
            <Select
              menuPortalTarget={document.body}
              options={
                props?.field?.systemFieldName === "CollectorID"
                  ? RadioOptions?.length
                    ? [{ value: "N/A", label: "N/A" }, ...RadioOptions]
                    : [
                        { value: "N/A", label: "N/A" },
                        ...(props?.selectOpt || []),
                      ]
                  : RadioOptions?.length
                  ? RadioOptions
                  : props?.selectOpt || []
              }
              placeholder={t(label)}
              theme={(theme) => styles(theme)}
              value={setDropDownValue(
                RadioOptions?.length
                  ? [{ value: "N/A", label: "N/A" }, ...RadioOptions]
                  : [
                      { value: "N/A", label: "N/A" },
                      ...(props?.selectOpt || []),
                    ],
                defaultValue
              )}
              noOptionsMessage={() => "N/A"}
              onChange={async (e: any) => {
                touched = true;
                let newInputs = await assignFormValues(
                  Inputs,
                  // dependenceyControls,
                  index,
                  depControlIndex,
                  fieldIndex,
                  e.value,
                  isDependency,
                  repeatFieldSection,
                  isDependencyRepeatFields,
                  repeatFieldIndex,
                  repeatDependencySectionIndex,
                  repeatDepFieldIndex,
                  e.label,
                  props?.setInputs
                );

                if (ArrayReqId) {
                  const infectiousDataCopy = [...props?.infectiousData];
                  infectiousDataCopy[
                    FindIndex(infectiousDataCopy, ArrayReqId)
                  ].sections = newInputs;
                  props?.setInfectiousData &&
                    props?.setInfectiousData([...infectiousDataCopy]);
                } else {
                  props?.setInputs(newInputs);
                }

                !props?.repeatInputs
                  ? handleChange(
                      e.name,
                      e.value,
                      e.id,
                      depfield,
                      props?.searchID,
                      props?.controlId,
                      props?.index,
                      props?.section?.sectionName,
                      fieldIndex,
                      isDependency,
                      props?.infectiousInputs
                    )
                  : handleChangeRepeatFields(
                      e.name,
                      e.id,
                      depfield,
                      props?.searchID,
                      props?.controlId,
                      props?.index,
                      sectionName,
                      fieldIndex
                    );
                _setState(props?.sysytemFieldName, e.value);
              }}
              isSearchable={true}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "var(--kt-input-border-color)",
                  color: "var(--kt-input-border-color)",
                }),
              }}
            />
            {props.enableRule && (
              <div className="form__error">
                <span>{t(props.enableRule)}</span>
              </div>
            )}
          </div>
        </>
      ) : null}
      {uiType === "Repeat" && (visible || isDependent) ? (
        <>
          <RepeatInputs
            repeatDependencyControls={props?.repeatDependencyControls}
            repeatFields={props?.repeatFields}
            repeatFieldsState={props?.repeatFieldsState}
            repeatDependencyControlsState={props?.repeatDependencyControlsState}
            index={props?.index}
            fieldIndex={props?.fieldIndex}
            Inputs={Inputs}
            setInputs={props?.setInputs}
            formState={props?.formState}
            setFormState={props?.setFormState}
            repeatControlLength={
              Inputs[index].fields.filter(
                (item: any) => item?.uiType === "Repeat"
              ).length
            }
            pageId={props?.pageId}
            displatClassForBtn={
              fieldIndex + 1 ==
              Inputs[index].fields.filter(
                (item: any) => item?.uiType === "Repeat"
              ).length
                ? ""
                : "d-none"
            }
            sectionName={sectionName}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
            rror={props?.field?.enableRule}
            errorFocussedInput={props?.errorFocussedInput}
            requisitionflow={props.requisitionflow}
          />
        </>
      ) : null}

      {uiType === "CheckBox" && (visible || isDependent) ? (
        <>
          <Checkbox
            spanClassName="mb-2 mr-2"
            label={props.label}
            sectionName={props?.section?.sectionName}
            parentDivClassName={displayType}
            depOptionID={props?.optionID}
            onChange={(e: any) => {
              let obj: any = {};
              const inputValue = e.target.value;
              obj[e.target?.name] = inputValue;
              props?.setFormData({
                ...props?.formData,
                ...obj,
                sectionName: props?.section?.sectionName,
              });

              // changeHandler(e);
              assignFormValues(
                Inputs,
                // dependenceyControls,
                index,
                depControlIndex,
                fieldIndex,
                e.target.checked,
                isDependency,
                repeatFieldSection,
                isDependencyRepeatFields,
                repeatFieldIndex,
                repeatDependencySectionIndex,
                repeatDepFieldIndex,
                e.target.name,
                props?.setInputs
              );

              _setState(props?.sysytemFieldName, e.target.checked);
            }}
            sectionId={props.sectionId}
            defaultValue={props?.defaultValue}
            Inputs={props.Inputs}
            infectiousData={props?.infectiousData}
            apiCallCondition={apiCallCondition}
          />
        </>
      ) : null}
      {uiType === "Switch" && (visible || isDependent) ? (
        <>
          <Switch
            spanClassName="mb-2 mr-2"
            label={props.label}
            sectionName={props?.section?.sectionName}
            parentDivClassName={displayType}
            depOptionID={props?.optionID}
            onChange={(e: any) => {
              let obj: any = {};
              const inputValue = e.target.value;
              obj[e.target?.name] = inputValue;
              props?.setFormData({
                ...props?.formData,
                ...obj,
                sectionName: props?.section?.sectionName,
              });
              assignFormValues(
                Inputs,
                // dependenceyControls,
                index,
                depControlIndex,
                fieldIndex,
                e.target.checked,
                isDependency,
                repeatFieldSection,
                isDependencyRepeatFields,
                repeatFieldIndex,
                repeatDependencySectionIndex,
                repeatDepFieldIndex,
                e.target.name,
                props?.setInputs
              );
              _setState(props?.sysytemFieldName, e.target.checked);
            }}
            sectionId={props.sectionId}
            defaultValue={props.defaultValue}
            Inputs={props.Inputs}
            visible={props.field.visible}
            RequisitionName={props.rname}
          />
        </>
      ) : null}
      {uiType === "RawText" && (visible || isDependent) ? (
        <>
          <RawText
            spanClassName="mb-2 mr-2"
            label={props.label}
            sectionName={props?.section?.sectionName}
            parentDivClassName={displayType}
            depOptionID={props?.optionID}
            sectionId={props.sectionId}
          />
        </>
      ) : null}
      {uiType === "TestingOptionCheckboxes" ? (
        <>
          <ToxTestingOptionCheckbox
            spanClassName="mb-2 mr-2"
            parentDivClassName={displayType}
            Inputs={props.Inputs}
            index={props.index}
            dependenceyControls={dependenceyControls}
            depControlIndex={depControlIndex}
            fieldIndex={fieldIndex}
            isDependency={isDependency}
            repeatFieldSection={repeatFieldSection}
            isDependencyRepeatFields={isDependencyRepeatFields}
            repeatFieldIndex={repeatFieldIndex}
            repeatDependencySectionIndex={repeatDependencySectionIndex}
            repeatDepFieldIndex={repeatDepFieldIndex}
            setInputs={props.setInputs}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
            ArrayReqId={props.ArrayReqId}
          />
        </>
      ) : null}
      {uiType === "CheckboxBlock" && (visible || isDependent) ? (
        <>
          <Checkbox
            spanClassName="mb-2 mr-2"
            label={props.label}
            onChange={(e: any) => {
              const inputValue = e.target.checked;
              props?.setFormData((preVal: any) => {
                return {
                  ...preVal,
                  [e.target?.name]: inputValue,
                  sectionName: props?.section?.sectionName,
                };
              });
              assignFormValues(
                Inputs,
                index,
                depControlIndex,
                fieldIndex,
                inputValue,
                isDependency,
                repeatFieldSection,
                isDependencyRepeatFields,
                repeatFieldIndex,
                repeatDependencySectionIndex,
                repeatDepFieldIndex,
                undefined,
                props?.setInputs
              );
              assignFormValuesForMedicalNecessity(
                Inputs,
                index,
                fieldIndex,
                inputValue,
                isDependency,
                undefined
              );
              props.setIsShown(!props.isShown);
            }}
            checked={props.defaultValue}
            parentDivClassName={displayType}
            depOptionID={props?.optionID}
            disabled={props.disabled}
            required={props.required}
            apiCallCondition={apiCallCondition}
            sectionId={props.sectionId}
          />
        </>
      ) : null}
      {uiType === "CheckBoxList" &&
      props.sectionId !== 20 &&
      (visible || isDependent) ? (
        <>
          <CheckBoxList
            parentDivClassName={displayType}
            RadioOptions={RadioOptions}
            sysytemFieldName={sysytemFieldName}
            displayFieldName={label}
            defaultValue={setJSONDataFormat(props.field.defaultValue)}
            Inputs={props.Inputs}
            index={props.index}
            onChange={(e: any, value: string, label: string) => {
              const checked = e.target.checked;
              let obj = {
                value: value,
                label: label,
              };
              let inputValue: any = [];
              if (checked) {
                let result = isJson(props.field.defaultValue);
                let defaultValue = props.field.defaultValue;
                if (result) {
                  defaultValue = JSON.parse(defaultValue);
                }
                inputValue = [obj, ...defaultValue];
              }
              if (!checked) {
                let result = isJson(props.field.defaultValue);
                let defaultValue = props.field.defaultValue;
                if (result) {
                  defaultValue = JSON.parse(defaultValue);
                }
                inputValue =
                  Array.isArray(defaultValue) &&
                  defaultValue?.filter(
                    (drugAllergiesInfo: any) =>
                      drugAllergiesInfo?.value !== value
                  );
              }
              assignFormValues(
                Inputs,
                index,
                depControlIndex,
                fieldIndex,
                inputValue,
                isDependency,
                repeatFieldSection,
                isDependencyRepeatFields,
                repeatFieldIndex,
                repeatDependencySectionIndex,
                repeatDepFieldIndex,
                undefined,
                props?.setInputs
              );
            }}
            error={props?.field?.enableRule}
            sectionName={props?.sectionName}
            defaultValueWithoutParse={props.field.defaultValue}
            dependenceyControls={dependenceyControls}
            depControlIndex={depControlIndex}
            fieldIndex={fieldIndex}
            isDependency={isDependency}
            repeatFieldSection={repeatFieldSection}
            isDependencyRepeatFields={isDependencyRepeatFields}
            repeatFieldIndex={repeatFieldIndex}
            repeatDependencySectionIndex={repeatDependencySectionIndex}
            repeatDepFieldIndex={repeatDepFieldIndex}
            setInputs={props?.setInputs}
            infectiousData={props?.infectiousData}
            apiCallCondition={apiCallCondition}
            sectionId={props.sectionId}
          />
        </>
      ) : null}

      {(uiType === "AutoComplete" || uiType === "CheckBoxList") &&
      props?.sectionId === 20 &&
      (visible || isDependent) ? (
        <ActiveMedicationListCheckbox
          parentDivClassName={displayType}
          options={props.field.medicationList}
          sysytemFieldName={sysytemFieldName}
          displayFieldName={label}
          defaultValue={setJSONDataFormat(props.field.defaultValue)}
          Inputs={props.Inputs}
          index={props.index}
          onChange={(e: any, value: string, label: string) => {
            const checked = e;
            let obj = {
              value: value,
              label: label,
            };
            let inputValue: any = [];
            if (checked) {
              let result = isJson(props.field.defaultValue);
              let defaultValue = props.field.defaultValue;
              if (result) {
                defaultValue = JSON.parse(defaultValue);
              }
              inputValue = [obj, ...defaultValue];
            }
            if (!checked) {
              let result = isJson(props.field.defaultValue);
              let defaultValue = props.field.defaultValue;
              if (result) {
                defaultValue = JSON.parse(defaultValue);
              }
              inputValue = defaultValue.filter(
                (drugAllergiesInfo: any) => drugAllergiesInfo.value !== value
              );
            }
            assignFormValues(
              Inputs,
              index,
              depControlIndex,
              fieldIndex,
              inputValue,
              isDependency,
              repeatFieldSection,
              isDependencyRepeatFields,
              repeatFieldIndex,
              repeatDependencySectionIndex,
              repeatDepFieldIndex,
              undefined,
              props?.setInputs
            );
          }}
          error={props?.field?.enableRule}
          sectionName={props?.sectionName}
          defaultValueWithoutParse={props.field.defaultValue}
          dependenceyControls={dependenceyControls}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          setInputs={props?.setInputs}
          selectedMedications={selectedMedications}
          setSelectedMedications={setSelectedMedications}
          ArrayReqId={props?.ArrayReqId}
          infectiousData={props?.infectiousData}
        />
      ) : null}
      {uiType === "DynamicAutoComplete" && (visible || isDependent) ? (
        <>
          <DynamicAutoComplete
            error={props?.enableRule}
            data={props?.field}
            Inputs={Inputs}
            setInputs={props?.setInputs}
            dependenceyControls={dependenceyControls}
            index={index}
            depControlIndex={depControlIndex}
            fieldIndex={fieldIndex}
            isDependency={isDependency}
            repeatFieldSection={repeatFieldSection}
            isDependencyRepeatFields={isDependencyRepeatFields}
            repeatFieldIndex={repeatFieldIndex}
            repeatDependencySectionIndex={repeatDependencySectionIndex}
            repeatDepFieldIndex={repeatDepFieldIndex}
            defaultValue={props?.defaultValue}
            infectiousData={props?.infectiousData}
            setInfectiousData={props?.setInfectiousData}
            ArrayReqId={props?.ArrayReqId}
          />
        </>
      ) : null}
      {uiType === "AutoComplete" &&
      props?.sectionId !== 20 &&
      (visible || isDependent) ? (
        <>
          <AutoComplete
            setValues={setPatientValues}
            loadReqSec={loadReqSec}
            parentDivClassName={displayType}
            placeholder={`${props.label}`}
            label={props.label}
            required={required}
            sysytemFieldName={sysytemFieldName}
            error={props?.enableRule}
            //inputEmpty={inputEmpty}
            apiCall="Patient"
            facilityIdForSearch={
              props?.Requisition?.ReqReducer?.ReqObjData?.facilityId
            }
            setInputs={props.setInputs}
            defaultValue={props.defaultValue}
            Inputs={Inputs}
            dependenceyControls={dependenceyControls}
            index={index}
            depControlIndex={depControlIndex}
            fieldIndex={fieldIndex}
            isDependency={isDependency}
            repeatFieldSection={repeatFieldSection}
            isDependencyRepeatFields={isDependencyRepeatFields}
            repeatFieldIndex={repeatFieldIndex}
            repeatDependencySectionIndex={repeatDependencySectionIndex}
            repeatDepFieldIndex={repeatDepFieldIndex}
            errorFocussedInput={props?.errorFocussedInput}
          />
        </>
      ) : null}

      {/* ------------------ */}

      {uiType === "Date" && (visible || isDependent) ? (
        <>
          <DatePicker
            label={props.label}
            parentDivClassName={displayType}
            required={props.required}
            Inputs={Inputs}
            setInputs={props?.setInputs}
            dependenceyControls={dependenceyControls}
            index={index}
            depControlIndex={depControlIndex}
            fieldIndex={fieldIndex}
            isDependency={isDependency}
            repeatFieldSection={repeatFieldSection}
            isDependencyRepeatFields={isDependencyRepeatFields}
            repeatFieldIndex={repeatFieldIndex}
            repeatDependencySectionIndex={repeatDependencySectionIndex}
            repeatDepFieldIndex={repeatDepFieldIndex}
            defaultValue={props.defaultValue}
            errorFocussedInput={props?.errorFocussedInput}
            name={sysytemFieldName}
            field={props.field}
            infectiousData={props?.infectiousData}
            setInfectiousData={props?.setInfectiousData}
            ArrayReqId={props?.ArrayReqId}
          />
        </>
      ) : null}
      {uiType === "Time" && (visible || isDependent) ? (
        <>
          <TimeInput
            parentDivClassName={displayType}
            type={uiType.toLowerCase()}
            label={props.label}
            required={required}
            name={sysytemFieldName}
            mask={props.mask}
            length={props?.length ?? ""}
            error={props?.enableRule}
            errorFocussedInput={props?.errorFocussedInput}
            onChange={async (e: any) => {
              const currentValue = e.target.value;
              _setState(props?.sysytemFieldName, currentValue);
              let newInputs = await assignFormValues(
                Inputs,
                // dependenceyControls,
                index,
                depControlIndex,
                fieldIndex,
                currentValue,
                isDependency,
                repeatFieldSection,
                isDependencyRepeatFields,
                repeatFieldIndex,
                repeatDependencySectionIndex,
                repeatDepFieldIndex,
                undefined,
                props?.setInputs
              );
              if (!location?.state?.reqId) {
                props?.setInputs(newInputs);
              }
            }}
            value={props.defaultValue}
            sectionId={props.sectionId}
          />
        </>
      ) : null}
      {uiType === "TextArea" && (visible || isDependent) ? (
        <TextArea
          parentDivClassName={displayType}
          label={props.label}
          required={required}
          name={sysytemFieldName}
          length={props?.length ?? ""}
          error={props?.enableRule}
          errorFocussedInput={props?.errorFocussedInput}
          id={props.sectionId}
          onChange={(e: any) => {
            if (uiType === "Integer" && e.target.value < 0) {
              return;
            }
            let inputValue;
            //const reg = /^\d+$/;
            if (props?.length === 4) {
              inputValue = e.target.value.replace(/[^0-9]/g, "");
            }
            if (props?.length !== 4) {
              inputValue = e.target.value;
            }

            assignFormValues(
              Inputs,
              // dependenceyControls,
              index,
              depControlIndex,
              fieldIndex,
              inputValue,
              isDependency,
              repeatFieldSection,
              isDependencyRepeatFields,
              repeatFieldIndex,
              repeatDependencySectionIndex,
              repeatDepFieldIndex,
              undefined,
              props?.setInputs
            );

            _setState(props?.sysytemFieldName, e.target.value);
          }}
          value={
            uiType === "Date"
              ? moment(props.defaultValue, "MM/DD/YYYY").format("YYYY-MM-DD")
              : props.defaultValue
          }
        />
      ) : null}
      {(uiType === "Email" || uiType === "TextBox" || uiType === "Integer") &&
      (visible || isDependent) ? (
        <>
          <Input
            parentDivClassName={displayType}
            type={uiType === "Integer" ? "number" : uiType.toLowerCase()}
            label={props.label}
            required={required}
            name={sysytemFieldName}
            mask={props.mask}
            length={props?.length ?? ""}
            error={props?.enableRule}
            errorFocussedInput={props?.errorFocussedInput}
            onChange={async (e: any) => {
              let inputValue = e.target.value;
              // Prevent negative numbers for "Integer"
              if (uiType === "Integer" && inputValue < 0) {
                return; // Don't allow negative numbers for Integer type
              }
              // Handle sanitization for numeric inputs (when length is 4)
              if (props?.length === 4) {
                inputValue = inputValue.replace(/[^0-9]/g, ""); // Allow only numeric characters
              }
              // Immediate state update for UI responsiveness
              _setState(sysytemFieldName, inputValue);
              // Async logic for form values
              const newInputs = await assignFormValues(
                Inputs,
                // dependenceyControls,
                index,
                depControlIndex,
                fieldIndex,
                inputValue,
                isDependency,
                repeatFieldSection,
                isDependencyRepeatFields,
                repeatFieldIndex,
                repeatDependencySectionIndex,
                repeatDepFieldIndex,
                undefined,
                props?.setInputs
              );

              // Handle updates based on the conditions provided
              if (!location?.state?.reqId && !ArrayReqId) {
                props?.setInputs(newInputs); // Update inputs
              }

              if (!location?.state?.reqId && ArrayReqId) {
                // Deep copy the infectious data to avoid mutation
                const infectiousDataCopy = JSON.parse(
                  JSON.stringify(props?.infectiousData)
                );
                infectiousDataCopy[
                  FindIndex(props?.infectiousData, ArrayReqId)
                ].sections = newInputs;
                props?.setInfectiousData &&
                  props?.setInfectiousData([...infectiousDataCopy]); // Update infectious data
              }
            }}
            value={
              uiType === "Date"
                ? moment(props.defaultValue, "MM/DD/YYYY").format("YYYY-MM-DD")
                : props.defaultValue
            }
            sectionId={props.sectionId}
          />
        </>
      ) : null}
      {uiType === "Label" && (visible || isDependent) && (
        <>
          {props.sectionId === 21 ? (
            <>
              <div className={`mb-5 ${displayType}`}>
                <h6 className="px-4 py-4 bg-light-warning text-dark fw-400 w-100 rounded-2">
                  {" "}
                  {t(props.label)}:
                </h6>
              </div>
            </>
          ) : (
            <div className={`mb-5 ${displayType}`}>
              <div className="d-flex justify-content-start align-items-center">
                <h6 style={{ color: "#69A54B" }}> {t(props.label)}:</h6>
                <h6 className="text-muted p-2">{t(props.defaultValue)}</h6>
              </div>
            </div>
          )}
        </>
      )}

      {uiType === "HeaderNonSelectablePanel" && (visible || isDependent) ? (
        <PanelsCheckBox
          panels={props?.panels}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          setInputs={props?.setInputs}
          displayType={displayType}
          sectionName={sectionName}
          sectionDisplayType={props?.sectionDisplayType}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          error={props?.enableRule}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          setCheckboxState={props.setCheckboxState}
          checkboxState={props.checkboxState}
          ArrayReqId={ArrayReqId}
        />
      ) : null}
      {uiType === "SpecimenSourceControl" && (visible || isDependent) ? (
        <PanelsCheckboxSpecimenSource
          panels={props?.specimenSources}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          setInputs={props?.setInputs}
          displayType={displayType}
          sectionName={sectionName}
          sectionDisplayType={props?.sectionDisplayType}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          error={props?.enableRule}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {uiType === "SelectablePanel" &&
      (visible || isDependent) &&
      props.sectionId !== 17 ? (
        <PanelsCheckboxSelected
          panels={props?.panels}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          displayType={props.displayType}
          error={props?.enableRule}
          setInputs={props?.setInputs}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {uiType === "HeaderSelectableOnlyPanel" &&
      (visible || isDependent) &&
      props.sectionId !== 17 ? (
        <HeaderSelectableOnlyPanel
          panels={props?.panels}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          displayType={props.displayType}
          error={props?.enableRule}
          setInputs={props?.setInputs}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {uiType === "MultipleSelectablePanel" && (visible || isDependent) ? (
        <MultipleHeaderSelectable
          panels={props?.panels}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          displayType={props.displayType}
          error={props?.enableRule}
          setInputs={props?.setInputs}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {uiType === "SearchableDropDown" && (visible || isDependent) ? (
        <AdditionalTest
          panels={props?.panels}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          displayType={props.displayType}
          error={props?.enableRule}
          setInputs={props?.setInputs}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {uiType === "SelectablePanel" &&
      (visible || isDependent) &&
      props.sectionId === 17 ? (
        <ToxTestingOption
          panels={props?.panels}
          Inputs={Inputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props.field}
          displayType={props.displayType}
          error={props?.enableRule}
          setInputs={props?.setInputs}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          name={props?.field?.systemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {/* {uiType === "RadioButton" &&
      (visible || isDependent) &&
      props.sectionId === 36 ? (
        <POCResults
          parentDivClassName={displayType}
          RadioOptions={RadioOptions}
          label={props?.label}
          name={props?.sysytemFieldName}
          error={props?.enableRule}
          required={required}
          onChange={(e: any, value: any, label: any, dropdownText: any) => {
            const inputValue = value;
            assignFormValues(
              Inputs,
              dependenceyControls,
              index,
              depControlIndex,
              fieldIndex,
              inputValue,
              isDependency,
              repeatFieldSection,
              isDependencyRepeatFields,
              repeatFieldIndex,
              repeatDependencySectionIndex,
              repeatDepFieldIndex,
              dropdownText,
              props?.setInputs
            );
            !props?.repeatInputs
              ? handleChange(
                  e.target.attributes["data-name"].value,
                  e.target.value,
                  e.target.attributes["data-id"].value,
                  depfield,
                  props?.searchID,
                  props?.controlId,
                  props?.index,
                  props?.section?.sectionName,
                  fieldIndex,
                  isDependency,
                  props?.infectiousInputs
                )
              : handleChangeRepeatFields(
                  e.target.attributes["data-name"].value,
                  e.target.attributes["data-id"].value,
                  depfield,
                  props?.searchID,
                  props?.controlId,
                  props?.index,
                  sectionName,
                  fieldIndex,
                );
            _setState(props?.sysytemFieldName, e.target.value);
          }}
          index={props?.index}
          depOptionID={props?.optionID}
          setShowHideFields={setShowHideFields}
          inputElement={inputElement}
          sectionName={props?.sectionName}
          defaultValue={props.defaultValue.split(" ").join("")}
          repeatFieldIndex={props?.repeatFieldIndex}
          errorFocussedInput={props?.errorFocussedInput}
          Inputs={Inputs}
          ArrayReqId={props?.ArrayReqId}
          Rname={props.rname}
          systemFieldName={props?.field?.systemFieldName}
          infectiousData={props?.infectiousData}
          setInputs={props?.setInputs}
          sectionId={props.sectionId}
          FindIndex={FindIndex}
        />
      ) : null} */}

      {uiType === "RadioButton" && (visible || isDependent) ? (
        <Radio2
          parentDivClassName={displayType}
          RadioOptions={RadioOptions}
          label={props?.label}
          name={props?.sysytemFieldName}
          error={props?.enableRule}
          required={props.required}
          onChange={async (
            e: any,
            value: any,
            label: any,
            dropdownText: any
          ) => {
            const inputValue = value;
            await assignFormValues(
              Inputs,
              index,
              depControlIndex,
              fieldIndex,
              inputValue,
              isDependency,
              repeatFieldSection,
              isDependencyRepeatFields,
              repeatFieldIndex,
              repeatDependencySectionIndex,
              repeatDepFieldIndex,
              dropdownText,
              props?.setInputs
            );

            !props?.repeatInputs
              ? handleChange(
                  e.target.attributes["data-name"].value,
                  e.target.value,
                  e.target.attributes["data-id"].value,
                  depfield,
                  props?.searchID,
                  props?.controlId,
                  props?.index,
                  props?.section?.sectionName,
                  fieldIndex,
                  isDependency,
                  props?.infectiousInputs
                )
              : handleChangeRepeatFields(
                  e.target.attributes["data-name"].value,
                  e.target.attributes["data-id"].value,
                  depfield,
                  props?.searchID,
                  props?.controlId,
                  props?.index,
                  sectionName,
                  fieldIndex
                );
            _setState(props?.sysytemFieldName, e.target.value);
          }}
          sectionId={props?.section?.sectionId}
          index={props?.index}
          depOptionID={props?.optionID}
          setShowHideFields={setShowHideFields}
          inputElement={inputElement}
          sectionName={props?.sectionName}
          defaultValue={
            typeof props?.defaultValue === "string"
              ? props?.defaultValue.split(" ").join("")
              : props?.defaultValue
          }
          repeatFieldIndex={props?.repeatFieldIndex}
          errorFocussedInput={props?.errorFocussedInput}
          Inputs={Inputs}
          ArrayReqId={props?.ArrayReqId}
          Rname={props.rname}
          systemFieldName={props?.field?.systemFieldName}
          infectiousData={props?.infectiousData}
          setInputs={props?.setInputs}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          dependenceyControls={dependenceyControls}
        />
      ) : null}
      {uiType === "InsuranceProviderControl" && (visible || isDependent) ? (
        <ReactSelect
          parentDivClassName={displayType}
          required={required}
          label={props?.label}
          placeholder="InsuranceProviderControl"
          Inputs={Inputs}
          setInputs={props?.setInputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          isSearchable={true}
          error={props?.enableRule}
          inputValue={defaultValue}
          ControlType={uiType}
          name={props?.sysytemFieldName}
          errorFocussedInput={props?.errorFocussedInput}
          field={props?.field}
          //  insuranceDropdown={insuranceDropdown}
        />
      ) : null}
      {uiType === "Signature" && (visible || isDependent) ? (
        <>
          <div className={`${displayType}`}>
            <SignPad
              formData={props?.formData}
              formState={props?.formState}
              sectionName={props?.sectionName}
              padValue={props.field.signatureText ?? ""}
              defaultValue={props.field.defaultValue}
              Inputs={Inputs}
              dependenceyControls={dependenceyControls}
              index={index}
              depControlIndex={depControlIndex}
              fieldIndex={fieldIndex}
              isDependency={isDependency}
              repeatFieldSection={repeatFieldSection}
              isDependencyRepeatFields={isDependencyRepeatFields}
              repeatFieldIndex={repeatFieldIndex}
              repeatDependencySectionIndex={repeatDependencySectionIndex}
              repeatDepFieldIndex={repeatDepFieldIndex}
              error={props?.field?.enableRule}
              setInputs={props?.setInputs}
              name={props?.sysytemFieldName}
              errorFocussedInput={props?.errorFocussedInput}
              setCheck={props.setCheck}
              physicianId={props?.physicianId}
              setPhysicianId={props?.setPhysicianId}
              infectiousData={props?.infectiousData}
              setInfectiousData={props?.setInfectiousData}
              ArrayReqId={props?.ArrayReqId}
            />
          </div>
        </>
      ) : null}

      {uiType === "ReadmoreControl" && (visible || isDependent) ? (
        <>
          <div className={`${displayType} `}>
            <ReadMore
              spanClassName="mb-2 mr-2"
              label={props.label}
              length={props.defaultValue}
              parentDivClassName={displayType}
              depOptionID={props?.optionID}
              sectionId={props.sectionId}
            />
          </div>
        </>
      ) : null}
      {removeUi === true ? (
        <>
          <span onClick={() => props?.RemoveFields(props?.searchID, index)}>
            {/* <CrossIcon /> */}
            <div className="d-flex justify-content-between align-items-center">
              <button className="btn btn-icon btn-sm fw-bold btn-danger btn-icon-light">
                <CrossIcon className="fs-2hx text-gray-700 bi bi-x" />
              </button>
            </div>
          </span>
          <br />
        </>
      ) : null}
      {uiType === "RepeatEnd" && (visible || isDependent) ? (
        <>
          <hr />
          <button
            onClick={() => {
              props?.addFields(props);
            }}
            className="btn btn-primary sm mr-2 mt-2 order-2"
            style={{ width: "50%" }}
          >
            {t("Add")}
          </button>
        </>
      ) : null}
      {uiType === "File" && (visible || isDependent) ? (
        <div className={`${displayType} order-3`}>
          {" "}
          <FileUpload
            {...props}
            Inputs={Inputs}
            setInputs={props?.setInputs}
            dependenceyControls={dependenceyControls}
            index={index}
            depControlIndex={depControlIndex}
            fieldIndex={fieldIndex}
            isDependency={isDependency}
            repeatFieldSection={repeatFieldSection}
            isDependencyRepeatFields={isDependencyRepeatFields}
            repeatFieldIndex={repeatFieldIndex}
            repeatDependencySectionIndex={repeatDependencySectionIndex}
            repeatDepFieldIndex={repeatDepFieldIndex}
            controlId={props?.controlId}
            ArrayReqId={ArrayReqId}
            infectiousData={props.infectiousData}
            setInfectiousData={props.setInfectiousData}
          />
        </div>
      ) : null}
      {uiType === "ControlDynamicAutoComplete" ? (
        <ControlAutoDynamicComplete
          data={props?.field}
          Inputs={Inputs}
          setInputs={props?.setInputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props?.defaultValue}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          ArrayReqId={props?.ArrayReqId}
          handleServerSideDropdownOnChange={handleServerSideDropdownOnChange}
        />
      ) : null}

      {/* FIXME: need to clean props passing for this one */}
      {uiType === "ControlDynamicDropDown" ? (
        <ControlDynamicDropDown
          data={props?.field}
          Inputs={Inputs}
          setInputs={props?.setInputs}
          dependenceyControls={dependenceyControls}
          index={index}
          depControlIndex={depControlIndex}
          fieldIndex={fieldIndex}
          isDependency={isDependency}
          repeatFieldSection={repeatFieldSection}
          isDependencyRepeatFields={isDependencyRepeatFields}
          repeatFieldIndex={repeatFieldIndex}
          repeatDependencySectionIndex={repeatDependencySectionIndex}
          repeatDepFieldIndex={repeatDepFieldIndex}
          defaultValue={props?.defaultValue}
          infectiousData={props?.infectiousData}
          setInfectiousData={props?.setInfectiousData}
          ArrayReqId={props?.ArrayReqId}
        />
      ) : null}
      {uiType === "ServerSideDynamicDropDown" && (visible || isDependent) ? (
        <ServerSideDynamicDropDown
          data={props}
          label={label}
          required={required}
          RadioOptions={RadioOptions}
          defaultValue={defaultValue}
          sysytemFieldName={sysytemFieldName}
          inputElementDropdown={inputElementDropdown}
          handleServerSideDropdownOnChange={handleServerSideDropdownOnChange}
        />
      ) : null}
      {showRecursiveDep === true
        ? recursiveDependencyControls?.map((options: any) => (
            <>
              <div className="row">
                {options?.dependecyFields?.map((depfield: any) => (
                  <DynamicFormInputs
                    uiType={depfield?.uiType}
                    label={depfield?.displayFieldName}
                    sysytemFieldName={depfield?.systemFieldName}
                    displayType={
                      depfield?.displayType +
                      " " +
                      options?.name +
                      " " +
                      options?.name +
                      options.optionID
                    }
                    visible={depfield?.visible}
                    required={depfield?.required}
                    RadioOptions={
                      depfield?.uiType === "RadioButton"
                        ? depfield?.options
                        : ""
                    }
                    formData={props?.formData}
                    setFormData={props?.setFormData}
                    index={props?.index}
                    Inputs={props?.Inputs}
                    setInputs={props?.setInputs}
                    depOptionID={options.optionID}
                    setShowHideFields={setShowHideFields}
                    dependenceyControls={recursiveDependencyControls}
                    isDependent={true}
                    searchID={depfield?.searchID}
                    RemoveFields={props?.RemoveFields}
                    dependencyAction={options?.dependecyAction}
                    comp="dependency"
                    isShown={props.isShown}
                    setIsShown={props.setIsShown}
                    addFields={props?.addFields}
                    depfield={depfield}
                    depName={options?.name}
                  />
                ))}
              </div>
            </>
          ))
        : null}
    </>
  );
};

function mapStateToProps(state: any, ownProps: any) {
  return { Requisition: state };
}
export default connect(mapStateToProps)(DynamicFormInputs);
