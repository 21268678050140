import { useEffect, useRef, useState } from "react";
import { assignFormValues } from "../../../Utils/Auth";
import {
  getICDPanelsIndex,
  getSpecimenSourceIndex,
  panelsArrItemRemoval,
  panelsArrMakerToSend,
} from "../../../Utils/Common/Requisition";
import useLang from "./../../hooks/useLanguage";
const HeaderSelectableOnlyPanel = (props: any) => {
  const { t } = useLang();
  const [panelsArrToSend, setPanelsArrToSend] = useState<any>([]);
  useEffect(() => {
    setpanelsArrToSendForEdit();
  }, [props.panels]);

  function FindIndex(arr: any[], rid: any) {
    return arr.findIndex((i: any) => i.reqId === rid);
  }
  const handleChangeParent = (
    panelID: number,
    panelName: string,
    checked: boolean,
    index: number
  ) => {
    const name = panelName;
    let panelsArrToAppendCopy = [...panelsArrToSend];
    let inputsCopy = JSON?.parse(JSON?.stringify(props?.Inputs));
    let PanelIdRemove;
    if (checked) {
      let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
      let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
      if (specimenSourceIndex) {
        inputsCopy[specimenSourceIndex]?.fields?.forEach(
          (specimenSourceFields: any) => {
            specimenSourceFields?.specimenSources?.forEach(
              (panelsInfo: any) => {
                panelsInfo.isVisible = false;
              }
            );
          }
        );
      }
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              panelsInfo.isVisible = false; // Set all ICDPanels to false initially
            });
          }
        });
      }

      // Now set the clicked ICD panel's visibility to true
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == name) {
                panelsInfo.isVisible = true; // Set clicked ICD panel to true
              }
            });
          }
        });
      }
      //checking that only one parent checkbox is selected at same time
      inputsCopy[props.index]?.fields[props?.fieldIndex]?.panels?.forEach(
        (panel: any, i: number) => {
          if (i !== index) {
            panel.isSelected = false;
            PanelIdRemove = panel?.panelID;
            panel?.testOptions?.forEach((testOption: any) => {
              testOption.isSelected = false;
            });
          }
        }
      );
      if (PanelIdRemove) {
        let new_Vanished = panelsArrItemRemoval(
          PanelIdRemove,
          panelsArrToAppendCopy
        );
        let newInputs = assignFormValues(
          inputsCopy,
          // props?.dependenceyControls,
          props?.index,
          props?.depControlIndex,
          props?.fieldIndex,
          new_Vanished,
          props?.isDependency,
          props?.repeatFieldSection,
          props?.isDependencyRepeatFields,
          props?.repeatFieldIndex,
          props?.repeatDependencySectionIndex,
          props?.repeatDepFieldIndex,
          undefined,
          props?.setInputs
        );
        newInputs?.then((res) => {
          let infectiousDataCopy = JSON?.parse(
            JSON?.stringify(props?.infectiousData)
          );
          infectiousDataCopy[
            FindIndex(props?.infectiousData, props?.ArrayReqId)
          ].sections = res;
          props?.setInfectiousData([...infectiousDataCopy]);
        });
      }
      //starting of specimen source
      if (specimenSourceIndex) {
        inputsCopy[specimenSourceIndex]?.fields?.forEach(
          (specimenSourceFields: any) => {
            specimenSourceFields?.specimenSources?.forEach(
              (panelsInfo: any) => {
                if (panelsInfo?.panelName === name) {
                  panelsInfo.isVisible = true;
                } else {
                  panelsInfo.isVisible = false;
                }
              }
            );
          }
        );
      }

      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = true;
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions.forEach((testOptions: any) => {
        testOptions.isSelected = true;
      });
      let finalisedPanelsArrToSend = panelsArrMakerToSend(
        index,
        inputsCopy[props.index]?.fields[props?.fieldIndex]?.panels[index],
        panelsArrToAppendCopy,
        checked
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
      let newInputs = assignFormValues(
        inputsCopy,
        // props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        finalisedPanelsArrToSend,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs?.then((res) => {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }

    if (!checked) {
      let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
      let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
      inputsCopy[specimenSourceIndex]?.fields.forEach(
        (specimenSourceFields: any) => {
          specimenSourceFields.specimenSources?.forEach((panelsInfo: any) => {
            if (panelsInfo.panelName === name) {
              panelsInfo.isVisible = false;
            }
          });
        }
      );
      if (ICDPanelIndex) {
        inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
          if (IcdPanelsFields.systemFieldName == "ICDPanels") {
            IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
              if (panelsInfo.panelName == name) {
                panelsInfo.isVisible = false; // Set clicked ICD panel to false
              }
            });
          }
        });
      }
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].isSelected = false;
      inputsCopy[props.index].fields[props?.fieldIndex].panels[
        index
      ].testOptions.forEach((testOptions: any) => {
        testOptions.isSelected = false;
      });
      let finalisedPanelsArrToSend = panelsArrItemRemoval(
        panelID,
        panelsArrToAppendCopy
      );
      setPanelsArrToSend(finalisedPanelsArrToSend);
      let newInputs = assignFormValues(
        inputsCopy,
        // props?.dependenceyControls,
        props?.index,
        props?.depControlIndex,
        props?.fieldIndex,
        finalisedPanelsArrToSend,
        props?.isDependency,
        props?.repeatFieldSection,
        props?.isDependencyRepeatFields,
        props?.repeatFieldIndex,
        props?.repeatDependencySectionIndex,
        props?.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      newInputs?.then((res) => {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = res;
        props?.setInfectiousData([...infectiousDataCopy]);
      });
    }
  };
  const setpanelsArrToSendForEdit = () => {
    let panelsCopy = [...props?.panels];
    let filteredPanelsCopy = panelsCopy?.filter(
      (panelsData: any) => panelsData?.isSelected
    );
    let panelNameArr: any = [];
    filteredPanelsCopy?.forEach((panelData: any) => {
      panelNameArr?.push(panelData?.panelName);
    });
    let inputsCopy = [...props?.Inputs];
    let specimenSourceIndex = getSpecimenSourceIndex(inputsCopy);
    inputsCopy[specimenSourceIndex]?.fields?.forEach(
      (specimenSourceFields: any) => {
        specimenSourceFields?.specimenSources?.forEach((panelsInfo: any) => {
          if (panelNameArr?.includes(panelsInfo.panelName)) {
            panelsInfo.isVisible = true;
          }
        });
      }
    );
    let ICDPanelIndex = getICDPanelsIndex(inputsCopy);
    inputsCopy[ICDPanelIndex]?.fields?.forEach((IcdPanelsFields: any) => {
      if (IcdPanelsFields.systemFieldName == "ICDPanels") {
        IcdPanelsFields?.panels?.forEach((panelsInfo: any) => {
          if (panelNameArr?.includes(panelsInfo.panelName)) {
            panelsInfo.isVisible = true;
          }
        });
      }
    });
    assignFormValues(
      inputsCopy,
      // props?.dependenceyControls,
      props?.index,
      props?.depControlIndex,
      props?.fieldIndex,
      filteredPanelsCopy,
      props?.isDependency,
      props?.repeatFieldSection,
      props?.isDependencyRepeatFields,
      props?.repeatFieldIndex,
      props?.repeatDependencySectionIndex,
      props?.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    setPanelsArrToSend(filteredPanelsCopy);
  };
  const divElement = useRef<HTMLDivElement | null>(null); // Initialize ref for div
  useEffect(() => {
    if (props.error && divElement.current) {
      divElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [props.error]);

  return (
    <>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div id={props?.name} ref={divElement} tabIndex={-1}></div>
      {props?.panels?.map((options: any, index: number) => (
        <>
          {options.isVisible && (
            <div className={`${props?.displayType}`}>
              <div className="row">
                {/* ***************** 1/4 ****************** */}
                <div className="col-lg-12">
                  <div className="card shadow-sm mb-3 rounded">
                    <div
                      className="card-header d-flex justify-content-between align-items-center rounded min-h-35px px-4"
                      style={{ background: "#F3F6F9", borderRadius: "8px" }}
                    >
                      <div className="col-12">
                        <label className="form-check form-check-sm form-check-solid col-12">
                          <input
                            className="form-check-input mr-2 h-20px w-20px"
                            type="checkbox"
                            checked={options?.isSelected}
                            onChange={(e: any) => {
                              handleChangeParent(
                                options?.panelID,
                                options?.panelName,
                                e.target.checked,
                                index
                              );
                            }}
                          />
                          {<span className="fw-600">{options?.panelName}</span>}
                        </label>
                      </div>
                    </div>
                    <div className="card-body py-md-4 py-3 col-12">
                      <div className="row">
                        {Object.entries(
                          options?.testOptions?.reduce(
                            (acc: any, optionsChild: any) => {
                              const { typeOfTest } = optionsChild;
                              if (typeOfTest) {
                                // Only add to the group if typeOfTest is truthy
                                if (!acc[typeOfTest]) {
                                  acc[typeOfTest] = []; // Create a new array for each typeOfTest
                                }
                                acc[typeOfTest].push(optionsChild); // Add the current test under its typeOfTest category
                              } else {
                                // Handle null or undefined typeOfTest: put it in a special category
                                if (!acc["No Type"]) {
                                  acc["No Type"] = [];
                                }
                                acc["No Type"].push(optionsChild);
                              }
                              return acc;
                            },
                            {}
                          )
                        )
                          // Sort the keys (typeOfTest) alphabetically
                          .sort(([a], [b]) => a.localeCompare(b)) // Sort alphabetically
                          .map(([typeOfTest, tests]: any, index: any) => (
                            <div key={index}>
                              {/* Display typeOfTest header only if it's not "No Type" */}
                              {typeOfTest && typeOfTest !== "No Type" && (
                                <div className="fw-bold mb-3">{typeOfTest}</div>
                              )}

                              {/* Loop through the tests for this typeOfTest */}
                              <div className="row">
                                {tests.map(
                                  (optionsChild: any, childIndex: any) => (
                                    <div
                                      key={childIndex}
                                      className="mb-3 col-xl-4 col-lg-4 col-md-4 col-sm-6"
                                    >
                                      <label className="form-check form-check-sm align-items-start form-check-solid col-12">
                                        <span className="fw-400">
                                          {optionsChild?.testName}
                                        </span>
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default HeaderSelectableOnlyPanel;
