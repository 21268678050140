import { useEffect, useRef } from "react";
import MuiSkeleton from "../MuiSkeleton";
import useLang from "./../../hooks/useLanguage";

const TimeInput: React.FC<any> = (props) => {
  const { t } = useLang();
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.value = props.value;
    }
  }, [props.value]);

  return (
    <div
      className={
        props?.parentDivClassName
          ? `${props?.parentDivClassName} mb-4`
          : "col-lg-6 col-md-6 col-sm-12 mb-4"
      }
    >
      <label
        className={props?.required ? "required mb-2 fw-500" : "mb-2 fw-500"}
        htmlFor={props.id}
      >
        {t(props.label)}
      </label>

      {props?.loading ? (
        <MuiSkeleton />
      ) : (
        <input
          type="time"
          value={props.value}
          name={props.name}
          id={props.id}
          onChange={props.onChange}
          className="form-control bg-transparent"
          required={props.required}
          ref={inputElement}
        />
      )}
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
    </div>
  );
};

export default TimeInput;
