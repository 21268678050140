import { FindIndex, setValueIntoSessionStorage } from "Utils/Common/CommonMethods";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import RequisitionType from "../../../Services/Requisition/RequisitionTypeService";
import { assignFormValues, Decrypt } from "../../../Utils/Auth";
import { TrashIcon } from "../../Icons";
import useLang from "./../../hooks/useLanguage";

const SignPad = (props: any) => {
  const { t } = useLang();

  const userTKN = sessionStorage.getItem("userinfo");
  const DECRYPTED_TKN = Decrypt(userTKN);
  const userInfo = JSON.parse(DECRYPTED_TKN);
  const location = useLocation();
  const signCanvas = useRef(
    props.sysytemFieldName + props?.controlId
  ) as React.MutableRefObject<any>;

  const clear = () => {
    if (signCanvas.current) {
      props.Inputs[props.index].fields.forEach((Innerfields: any) => {
        if (Innerfields?.systemFieldName === props.name) {
          Innerfields.defaultValue = "";
        }
      });
      props.Inputs[props.index].fields[props.fieldIndex - 1].defaultValue = "";
      if (!props?.ArrayReqId) {
        props.setInputs([...props.Inputs]);
      }
      signCanvas?.current?.clear();
      signCanvas?.current?.on();
    }
  };

  useEffect(() => {
    if (props.defaultValue) {
      signPadTextAssign(props.padValue);
    }
  }, [props?.padValue]);

  const PreSelected = async (padVal: any) => {
    if (padVal) {
      let newInputs = await assignFormValues(
        props.Inputs,
        // props.dependenceyControls,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        padVal,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      if (props?.ArrayReqId) {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = newInputs;
        props?.setInfectiousData([...infectiousDataCopy]);
      } else {
        props.setInputs(newInputs);
      }

      signCanvas.current.fromDataURL(padVal);
    }
  };

  const signPadTextAssign = async (padVal?: any) => {
    signCanvas?.current?.clear();
    if (props.defaultValue && props.defaultValue.length > 200) {
      let image = `data:image/png;base64,${props.defaultValue}`;
      let newInputs = await assignFormValues(
        props.Inputs,
        // props.dependenceyControls,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        image,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      if (props?.ArrayReqId) {
        let infectiousDataCopy = JSON?.parse(
          JSON?.stringify(props?.infectiousData)
        );
        infectiousDataCopy[
          FindIndex(props?.infectiousData, props?.ArrayReqId)
        ].sections = newInputs;
        props?.setInfectiousData([...infectiousDataCopy]);
      } else {
        props.setInputs(newInputs);
      }
      setTimeout(() => {
        signCanvas.current.fromDataURL(image);
      });
    }
    const canvas = signCanvas?.current?.getCanvas();

    const ctx = canvas?.getContext("2d");
    ctx.font = "16px Georgia";
    ctx.textAlign = "center";

    if (padVal) {
      const y = 30;
      const digitalSigInfo = padVal.split("!");
      for (let index = 0; index < digitalSigInfo.length; index++) {
        const element = digitalSigInfo[index];
        const vert = y * (index + 1);
        ctx.fillText(element, 250, vert);
        if (index === 1) {
          ctx.beginPath();
          ctx.moveTo(200, 200);
          ctx.lineTo(y * (index + 2), 200);
          ctx.stroke();
        }
      }
      signCanvas?.current?.off();
    } else {
      signCanvas?.current?.on();
    }
  };

  const handleEndSignature = async () => {
    const imageBase64 = signCanvas?.current?.toDataURL();
    let newInputs = await assignFormValues(
      props.Inputs,
      // props.dependenceyControls,
      props.index,
      props.depControlIndex,
      props.fieldIndex,
      imageBase64,
      props.isDependency,
      props.repeatFieldSection,
      props.isDependencyRepeatFields,
      props.repeatFieldIndex,
      props.repeatDependencySectionIndex,
      props.repeatDepFieldIndex,
      undefined,
      props?.setInputs
    );
    if (props?.ArrayReqId) {
      let infectiousDataCopy = JSON?.parse(
        JSON?.stringify(props?.infectiousData)
      );
      infectiousDataCopy[
        FindIndex(props?.infectiousData, props?.ArrayReqId)
      ].sections = newInputs;
      props?.setInfectiousData([...infectiousDataCopy]);
    } else {
      props.setInputs(newInputs);
    }
  };

  const [phyId, setPhyId] = useState(sessionStorage.getItem("PhysicianID"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentPhyId = sessionStorage.getItem("PhysicianID");
      if (currentPhyId !== phyId) {
        setPhyId(currentPhyId);
      }
    }, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, [phyId]);

  useEffect(() => {
    if (!phyId) return;

    const fetchPhysicianSignature = async () => {
      const obj = {
        user_id: userInfo?.userId,
        physician_id: phyId,
      };
      try {
        const res = await RequisitionType.GetPhysicianSignature(obj);
        if (props.name === "PhysicianSignature") {
          // if (res?.data) PreSelected(res.data);
          if (!location?.state?.reqId && !res?.data) clear();

          if (res.data) {
            setValueIntoSessionStorage("physicianSign", res.data);
          } else {
            sessionStorage.removeItem("physicianSign");
          }
        }
      } catch (error) {
        console.error("Failed to fetch physician signature", error);
      }
    };
    fetchPhysicianSignature();
  }, [phyId]);

  const currentPad = props.Inputs[props.index].fields[props.fieldIndex];

  useEffect(() => {
    if (currentPad) {
      signCanvas.current.fromDataURL(currentPad.signatureText);
    }
  }, [currentPad]);

  // added refresh page state
  useEffect(() => {
    const navigationType = window.performance?.navigation?.type;
    if (navigationType === 1) {
      sessionStorage.removeItem("PhysicianID");
      sessionStorage.removeItem("physicianSign");
    } else {
      console.log("Page is not refreshed");
    }
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <SignatureCanvas
          penColor="black"
          canvasProps={{
            height: 200,
            className: "signatureCanvas",
            style: { backgroundColor: "#F3F6F9", width: "100%" },
          }}
          ref={signCanvas}
          onEnd={handleEndSignature}
        />
      </div>
      {props.error && (
        <div className="form__error">
          <span>{t(props.error)}</span>
        </div>
      )}
      <div>
        <button
          onClick={clear}
          className="mt-3 mb-3 btn btn-primary btn-sm px-4 mx-2 p-2"
        >
          <TrashIcon />
          {t("Clear")}
        </button>
      </div>
    </>
  );
};

export default SignPad;
